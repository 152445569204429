import { useState, useDebugValue } from 'react'
import BigNumber from 'bignumber.js'

import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import * as ethers from '@ethersproject/bignumber'

import { ChainId, JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from '@decibel-coin/decibel-contracts'
// import { basisPointsToPercent } from '@decibel-coin/decibel-contracts/lib/contract-utils'

import { BASE_BSC_SCAN_URLS } from '../config'
import { getFullDisplayBalance } from './formatBalance'

export const formatTime = (seconds: number) => {
  const minutes = new BigNumber(seconds).dividedBy(60).toFixed(2)

  return `${minutes} min`
}

export const formatBasePoints = (points: number) => {
  const percents = new BigNumber(points).dividedBy(100).toFixed(2)

  return `${percents} %`
}

export const formatPercent = (part: BigNumber.Value, total: BigNumber.Value) => {
  const partBN = new BigNumber(part)
  const totalBN = new BigNumber(total)

  const percents = (partBN.isEqualTo(0) || totalBN.isEqualTo(0)) 
    ? '0'
    : partBN.dividedBy(totalBN).multipliedBy(100).toFixed(2)

  return `${percents} %`
}


export const formatDBCToken = (amount: string | BigNumber) => {
  const amountBN = typeof amount === 'string' ? new BigNumber(amount): amount

  const formattedAmount = getFullDisplayBalance(
    amountBN,
    18,
    2
  )

  return `${formattedAmount} DBC`
}

export const formatBNBToken = (amount: string | BigNumber) => {
  const amountBN = typeof amount === 'string' ? new BigNumber(amount): amount

  const formattedAmount = getFullDisplayBalance(
    amountBN,
    18,
    2
  )

  return `${formattedAmount} BNB`
}

export const formatAddress = (address: string) => {
  const accountEllipsis = address
    ? `${address.substring(0, 3)}...${address.substring(address.length - 3)}`
    : null

  return accountEllipsis
}

export function useDebugState<S>(initialState: S | (() => S), name?: string) {
  const [value, setValue] = useState<S>(initialState);
  useDebugValue(`${name}: ${value}`);
  return [value, setValue];
}

function copyToClipboard(value) {
  const textField = document.createElement('textarea')
  textField.innerText = value

  document.body.appendChild(textField)

  textField.select()
  
  textField.focus()

  const result = document.execCommand('copy')

  textField.remove()

  return result
}

const normalizeString = (str: string) => {
  return str.toLowerCase().trim()
}

const loadScript = (src, id) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')

    script.id = id
    script.src = src
    script.crossOrigin = 'anonymous'
    script.async = true
    script.onload = resolve
    script.onerror = reject

    document.head.appendChild(script)
  })
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getBscScanLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainId: ChainId = ChainId.MAINNET,
): string {
  switch (type) {
    case 'transaction': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/tx/${data}`
    }
    case 'token': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/token/${data}`
    }
    case 'block': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/${data}`
    }
    case 'countdown': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/countdown/${data}`
    }
    default: {
      return `${BASE_BSC_SCAN_URLS[chainId]}/address/${data}`
    }
  }
}

// add 10%
export function calculateGasMargin(value: ethers.BigNumber): ethers.BigNumber {
  return value.mul(ethers.BigNumber.from(10000).add(ethers.BigNumber.from(1000))).div(ethers.BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercentV2(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

const breakpoints = {
  xs: 575,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

export {
  copyToClipboard,
  normalizeString,
  loadScript,
  breakpoints
}