import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'

import { useAccount } from 'state/account/hooks'

const AuthRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { account, isSignInPending } = useAccount()
  const { account: address } = useWeb3React()

  if (isSignInPending && !account) {
    return <div>...loading</div>
  }

  if (!account) {
    return <Redirect to="/signin" />
  }

  if (!account.isClubhouseAccountVerified) {
    return <Redirect exact to="/connect-account" />
  }

  // if (address && account.blockchainAddress && account.blockchainAddress !== address) {
  //   return <Redirect exact to="/connect-blockchain-account" />
  // }

  if (!account.isEmailVerified) {
    return <Redirect exact to="/verify-email" />
  }

  return (
    <Route
      {...rest}
    >
      {children}
    </Route>
  )
}

export default AuthRoute