import { getEnvVar } from '@decibel-coin/decibel-sdk/lib/utils'
import { ChainId } from '@decibel-coin/decibel-contracts'

import { BASE_BSC_SCAN_URL, BASE_URL } from 'config'
import { nodesSecure } from './getRpcUrl'

const APP_BLOCKCHAIN_NETWORK_CHAIN_ID = getEnvVar('APP_BLOCKCHAIN_NETWORK_CHAIN_ID')

const connectorLocalStorageKey = "connectorIdv2";
const walletLocalStorageKey = "wallet";
  
export interface Config {
  title: string;
  // icon: FC<SvgProps>;
  connectorId: ConnectorNames;
  // priority: number;
}

enum ConnectorNames {
  Injected = "injected",
  WalletConnect = "walletconnect",
  BSC = "bsc",
}

const metamaskConnector: Config = {
  title: "Metamask",
  connectorId: ConnectorNames.Injected,
}

const connectors: Config[] = [
  metamaskConnector
]

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
const setupNetwork = async () => {
  const provider = window.ethereum
  
  if (provider) {
    const chainId = parseInt(APP_BLOCKCHAIN_NETWORK_CHAIN_ID, 10)
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: `Binance Smart Chain ${chainId === ChainId.MAINNET ? 'Mainnet' : 'Testnet'}`,
            nativeCurrency: {
              name: 'BNB',
              symbol: 'bnb',
              decimals: 18,
            },
            rpcUrls: nodesSecure,
            blockExplorerUrls: [`${BASE_BSC_SCAN_URL}/`],
          },
        ],
      })
      return true
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
const registerToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number) => {
  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        // image: `${BASE_URL}/static/media/${tokenSymbol}.png`,
      },
    },
  })

  return tokenAdded
}

export {
  connectors,
  metamaskConnector,
  connectorLocalStorageKey,
  walletLocalStorageKey,
  ConnectorNames,
  setupNetwork,
  registerToken
}
