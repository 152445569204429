import sample from 'lodash/sample'
import { getEnvVar, createNodeUrl } from '@decibel-coin/decibel-sdk/lib/utils'


const NODE_ENV = getEnvVar('NODE_ENV')
const APP_BLOCKCHAIN_NETWORK_RPC_HOST = getEnvVar('APP_BLOCKCHAIN_NETWORK_RPC_HOST')
const APP_BLOCKCHAIN_NETWORK_RPC_PORT = getEnvVar('APP_BLOCKCHAIN_NETWORK_RPC_PORT')

const nodeUrl = createNodeUrl({
  NODE_ENV,
  APP_BLOCKCHAIN_NETWORK_RPC_HOST,
  APP_BLOCKCHAIN_NETWORK_RPC_PORT
})

const nodeUrlSecure = createNodeUrl({
  NODE_ENV: 'production',// NODE_ENV, Hack to support wallet_addEthereumChain request, it only workd with https
  APP_BLOCKCHAIN_NETWORK_RPC_HOST,
  APP_BLOCKCHAIN_NETWORK_RPC_PORT
})

// export const nodes = [process.env.APP_NODE_1, process.env.APP_NODE_2, process.env.APP_NODE_3]
export const nodes = [nodeUrl]
export const nodesSecure = [nodeUrlSecure]

const getNodeUrl = () => {
  return sample(nodes)
}

export default getNodeUrl
