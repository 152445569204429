import React, { FC, useMemo, ChangeEvent } from 'react'
import cn from 'classnames'

import { Input } from 'components/antd'

import styles from './styles.module.scss'

type Theme = 'white' | 'blue' | 'blue-light' | 'green' | 'red' | 'gray-light'
type Size = 'xs' | 'sm' | 'md' | 'lg'

interface IProps {
  error?: string
  label?: string
  placeholder?: string
  prefix?: string
  postfix?: string
  value: string
  className?: string
  isDisabled?: boolean
  size?: Size
  theme?: Theme
  type?: 'number' | 'text' | 'password'
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const TextField: FC<IProps> = ({
  error,
  label,
  placeholder,
  prefix,
  postfix,
  value,
  className,
  isDisabled,
  size = 'sm',
  theme = 'white',
  type = 'text',
  onChange
}) => {
  const rootCN = useMemo(() => {
    return cn(
      styles.root,
      className,
      { [styles.root__isDisabled]: isDisabled },
      { [styles[`root__style_${theme}`]]: true },
      { [styles[`root__size_${size}`]]: true }
    )
  }, [className, isDisabled, theme, size])

  return (
    <div className={rootCN}>
      {label && (
        <div className={styles.root_label}>
          {label}
        </div>
      )}

      <div className={cn(styles.root_control, {
        [styles.control__hasError]: !!error
      })}>
        {type === 'text' && (
          <Input
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            autoComplete='off'
          />
        )}

        {type === 'password' && (
          <Input.Password
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            autoComplete='off'
          />
        )}

        {!!error && (
          <div className={styles.root_error}>
            {error}
          </div>
        )}
      </div>  
    </div>
  )
}
  
export default TextField