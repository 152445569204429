import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { IRoom, Populated, TRoomMemberPopulated } from '@decibel-coin/decibel-sdk/lib/types/entities'
import { TListField, IGetRoomsReqQuery, IGetRoomMembersReqQuery, IGetRoomsResBody } from '@decibel-coin/decibel-sdk/lib/types/api'

import { useList, TMakeListReq, THandleListRes } from 'hooks/useList'

import { ApiService } from 'services'

import { Box, RoomPreview } from 'components'
import { FieldWrapper } from 'components/Filters/fields'

import styles from './styles.module.scss'

type TRoom = Populated<IRoom, 'club'>

interface IProps {
  getRoomsQuery?: IGetRoomsReqQuery
  getRoomMembersQuery?: IGetRoomMembersReqQuery
}

const RoomsList: React.FC<IProps> = ({
  getRoomsQuery,
  getRoomMembersQuery
}) => {  
  const [rooms, setRooms] = useState<TRoom[]>([])
  // const [roomMembers, setRoomMembers] = useState<TRoomMemberPopulated[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const makeRoomsListReq = async (query: IGetRoomsReqQuery) => {
    return ApiService.getRooms({
      club_id: getRoomsQuery.club_id ? getRoomsQuery.club_id : query.club_id,
      page: 1,
      sorting: query.sorting,
      state: query.state,
      reward_state: query.reward_state,
      farmed_by_account: query.farmed_by_account
    })
  }

  const handleRoomsListRes = (resBody: IGetRoomsResBody) => {
    setRooms(resBody.items)
  }

  const { handleUpdateField, isLoading: isGetRoomsLoading, load: loadRooms, fields } = useList(
    makeRoomsListReq as TMakeListReq,
    handleRoomsListRes as THandleListRes
  )
  
  const onUpdateField = (field: TListField) => (payload) => {
    handleUpdateField(field, payload)
  }

  const onCloseField = () => {}

  // const loadRoomMembers = useCallback(async () => {
  //   setIsLoading(true)

  //   const roomMembersRes = await ApiService.getRoomMembers({
  //     account_id: getRoomMembersQuery?.account_id,
  //     room_id: getRoomMembersQuery?.room_id
  //   })

  //   setRoomMembers(roomMembersRes)

  //   setIsLoading(false)
  // }, [getRoomMembersQuery])

  // useEffect(() => {
  //   loadRoomMembers()

  //   // loadRooms({
  //   //   page: 1,
  //   // })
  
  // }, [rooms])

  // useEffect(() => {
  //   console.log('location changed')
  // }, [location])

  // const roomsExtendedWithClubMember = useMemo(() => {
  //   return rooms.map(room => {
  //     const roomMember: TRoomMemberPopulated | undefined = roomMembers.find(member => 
  //       member.room === room.id &&
  //       member.account.id === getRoomMembersQuery?.account_id
  //     )

  //     return {
  //       room,
  //       roomMember
  //     }
  //   })

  // }, [rooms, roomMembers, getRoomMembersQuery])

  if ((rooms.length === 0) && isLoading) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.filter}>
        {fields.map((field, i) =>
          <div className={styles.filter_field} key={field.name}>
            <FieldWrapper
              field={field}
              mode={'inline'}
              onUpdateField={onUpdateField(field)}
              onCloseField ={onCloseField}
            />
          </div>
        )}
      </div>

      <div className={styles.list}>
        {rooms.map(room => (
          <RoomPreview
            key={room.id}
            className={styles.list_item}
            room={room}
            roomMember={undefined}
          />
        ))}
      </div>
    </div>
  )
}

export default RoomsList
