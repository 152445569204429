import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPrivateAccount } from '@decibel-coin/decibel-sdk/lib/types/entities'

import { UIState } from 'state/types'

const initialState: UIState = {
  breadcrumb: {
    clubs: {},
    rooms: {}
  }
}

export const UISlice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    setBreadcrumbData: (state, action: PayloadAction<{
      type: 'clubs' | 'rooms'
      title: string
      id: string
    }>) => {
      if (action.payload.type === 'clubs') {
        state.breadcrumb.clubs =  {
          [action.payload.id]: {
            title: action.payload.title
          }
        }
      } 

      if (action.payload.type === 'rooms') {
        state.breadcrumb.rooms =  {
          [action.payload.id]: {
            title: action.payload.title
          }
        }
      } 
    },
  }
})

// Actions
export const { setBreadcrumbData } = UISlice.actions

export default UISlice.reducer
