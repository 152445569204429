import React, { FC, useMemo } from 'react'
import { InputNumber } from 'components/antd'
import cn from 'classnames'
import { InputNumberProps } from 'antd/lib/input-number'

import styles from './styles.module.scss'

type Theme = 'white' | 'blue' | 'blue-light' | 'green' | 'red' | 'gray-light'
type Size = 'xs' | 'sm' | 'md' | 'lg'

interface IProps extends Omit<InputNumberProps<string>, 'size'> {
  error?: string
  label?: string
  placeholder?: string
  prefix?: string
  postfix?: string
  value: string
  className?: string
  isDisabled?: boolean
  size?: Size
  theme?: Theme
}

const NumberField: FC<IProps> = ({
  label,
  value,
  placeholder,
  min,
  max,
  onChange,
  prefix,
  postfix,
  error,
  className,
  isDisabled,
  size = 'sm',
  theme = 'white',
  stringMode,
}) => {
  const rootCN = useMemo(() => {
    return cn(
      styles.root,
      className,
      { [styles.root__isDisabled]: isDisabled },
      { [styles[`root__style_${theme}`]]: true },
      { [styles[`root__size_${size}`]]: true }
    )
  }, [className, isDisabled, theme, size])

  return (
    <div className={rootCN}>
      {label && (
        <div className={styles.section}>
          <div className={styles.label}>
            {label}
          </div>
        </div>
      )}

      <div className={styles.section}>
        <div className={styles.content}>
          {!!prefix && 
            <div className={styles.prefix}>
              {prefix}
            </div>
          }

          <div className={cn(
            styles.control,
            { [styles.control__hasError]: !!error }
          )}>
            <InputNumber
              value={value}
              placeholder={placeholder}
              min={min}
              max={max}
              stringMode={stringMode}
              onChange={onChange}
            />

            {!!error && (
              <div className={styles.error}>
                {error}
              </div>
            )}
          </div>

          {!!postfix && 
            <div className={styles.postfix}>
              {postfix}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
  
export default NumberField