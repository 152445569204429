import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { Trade, TokenAmount, Fraction, CurrencyAmount, ETHER } from '@decibel-coin/decibel-contracts'
import { Bep20 } from '@decibel-coin/decibel-contracts/lib/contracts/types/Bep20'

import { useCallback, useEffect, useMemo, useState } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useTokenAllowance, { useTokenAllowanceV3 } from './useTokenAllowance'
import { calculateGasMargin } from '../utils'
import { useTokenContract } from './useContract'

export enum ApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallbackV2(
  amountToApprove?: CurrencyAmount,
  spender?: string,
  minAmountToApprove?: CurrencyAmount
): [ApprovalState, () => Promise<void>] {
  const minAmount = minAmountToApprove || amountToApprove

  const { account } = useActiveWeb3React()
  const token = amountToApprove instanceof TokenAmount ? amountToApprove.token : undefined

  // const currentAllowance = useTokenAllowance(token, account ?? undefined, spender)
  const currentAllowance = useTokenAllowanceV3(token, account ?? undefined, spender)
  // const pendingApproval = useHasPendingApproval(token?.address, spender)
  const [hasPendingApproval, setHasPendingApproval] = useState(false)
  
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency === ETHER) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    return currentAllowance.lessThan(new Fraction(minAmount.numerator, minAmount.denominator))
      ? hasPendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, minAmount, currentAllowance, spender, hasPendingApproval])

  const tokenContract = useTokenContract(token?.address) as any as Bep20

  const approve = useCallback(async (): Promise<void> => {
    setHasPendingApproval(true)

    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }
    if (!token) {
      console.error('no token')
      return
    }

    if (!tokenContract) {
      console.error('tokenContract is null')
      return
    }

    if (!amountToApprove) {
      console.error('missing amount to approve')
      return
    }

    if (!minAmount) {
      console.error('missing min amount to approve')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    const useExact = false

    // eslint-disable-next-line consistent-return
    return tokenContract.approve(spender, useExact ? amountToApprove.raw.toString() : MaxUint256)
      .then(async (response: TransactionResponse) => {
        await response.wait()
        
        setHasPendingApproval(false)
        console.log('Success to approve token')
      })
      .catch((error: Error) => {
        setHasPendingApproval(false)
        console.error('Failed to approve token', error)
        throw error
      })
  }, [approvalState, token, tokenContract, amountToApprove, minAmount, spender])

  return [approvalState, approve]
}