import { useContext } from 'react'
import { RefreshContext } from 'contexts/RefreshContext'

const useRefresh = () => {
  const { superSlow, slow, fast, superFast } = useContext(RefreshContext)

  return {
    superSlowRefresh: superSlow,
    slowRefresh: slow,
    fastRefresh: fast,
    superFastRefresh: superFast
  }
}

export default useRefresh
