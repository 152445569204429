import React, { FC, useMemo } from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

interface IProps {
  className?: string
  text?: string
  isToggled?: boolean
  onToggle: () => void
}

const Toggler: FC<IProps> = ({
  className,
  text,
  isToggled,
  onToggle
}) => {
  const rootCN = useMemo(() => {
    return cn(
      styles.root,
      className,
      { [styles.root__isToggled]: isToggled }
    )
  }, [className, isToggled])

  return (
    <div
      className={rootCN}
      onClick={onToggle}
    >
      <div className={styles.root_text}>
        {text}
      </div>

      <div className={styles.root_icon}/>
    </div>
  )
}
  
export default Toggler