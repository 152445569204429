import React from 'react'

import useToast from 'hooks/useToast'

import { copyToClipboard } from 'utils'

import styles from './styles.module.scss'

interface IProps {
  data: string | number
}

const CopyToClipboardBtn: React.FC<IProps> = (props) => {
  const { toastSuccess } = useToast()

  const handleCopy = (data: string | number) => {
    if (copyToClipboard(data)) {   
      toastSuccess('Copied')
    }
  }

  return (
    <div
      className={styles.root}
      onClick={() => handleCopy(props.data)}
    />
  )
}

export default CopyToClipboardBtn
