import { ChainId } from '@decibel-coin/decibel-contracts'
import { getEnvVar } from '@decibel-coin/decibel-sdk/lib/utils'

import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

const APP_BLOCKCHAIN_NETWORK_CHAIN_ID = getEnvVar('APP_BLOCKCHAIN_NETWORK_CHAIN_ID')

export const getAddress = (address: Address): string => {
  const chainId = APP_BLOCKCHAIN_NETWORK_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMasterAddress = () => {
  return getAddress(addresses.master)
}

export const getDecibelCoinAddress = () => {
  return getAddress(addresses.decibelCoin)
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}