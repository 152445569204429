import React, { useEffect, useState, useCallback } from 'react'
import cn from 'classnames'

import { Populated, IClub, IClubMember } from '@decibel-coin/decibel-sdk/lib/types/entities'

import { useAccount } from 'state/account/hooks'

import { Box, Toggler, ClubDetails, ClubMemberDetails } from 'components'
import { ApiService } from 'services'

import styles from './styles.module.scss'

interface IProps {
  className?: string
  club: IClub
}

const ClubPreview: React.FC<IProps> = ({
  className,
  club
} ) => {
  const [isToggled, setIsToggled] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [clubMember, setClubMember] = useState<Populated<IClubMember, 'account'> | undefined>(undefined)

  const { isAuthorized, account } = useAccount()

  const loadClubMember = useCallback(async () => {
    setIsLoading(true)

    const roomMembersRes = await ApiService.getClubMembers({
      club_id: club.id,
      account_id: isAuthorized ? account.id : undefined
    })

    const roomMember = roomMembersRes[0]
      ? roomMembersRes[0]
      : undefined

    setClubMember(roomMember)

    setIsLoading(false)
  }, [isAuthorized, account, club])

  useEffect(() => {
    if (isToggled) {
      loadClubMember()
    }
  }, [isToggled, loadClubMember])

  const handleToggle = () => {
    setIsToggled(!isToggled)
  }

  const handleLoadClubMember = () => {
    return loadClubMember()
  }

  const getToggler = () => (
    account
      ? (
        <Toggler
        text='Account details'
        isToggled={isToggled}
        onToggle={handleToggle}
        />
      )
      : undefined
  )

  return (
    <Box className={cn(styles.root, className)}>
      <ClubDetails
        club={club}
        actions={getToggler()}
      />

      {(isToggled) && (
        <>
          <div className={styles.root_separator}/>

          <ClubMemberDetails
            club={club}
            clubMember={clubMember}
            loadClubMember={handleLoadClubMember}
          />
        </>
      )}
    </Box>
  )
}

export default ClubPreview
