import { useSelector } from 'react-redux'

import { State, UIState } from '../types'
import { setBreadcrumbData } from '.'

export const useUI = () => {
  const { breadcrumb }: UIState = useSelector((state: State) => state.ui)

  return {
    breadcrumb,
    setBreadcrumbData
  }
}