import React, { FC, ChangeEvent, useMemo } from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

type Theme = 'white' | 'blue' | 'blue-light' | 'green' | 'red' | 'yellow' | 'gray-light'
type Size = 'xs' | 'sm' | 'md' | 'lg'

interface IProps {
  type?: 'button' | 'link'
  text: string
  className?: string
  isDisabled?: boolean
  size?: Size
  theme?: Theme
}

interface ILinkProps extends IProps {
  type: 'link'
  target?: '_blank' 
  linkUrl: string
}

interface IButtonProps extends IProps {
  type: 'button'
  onClick: () => void
}

const Button: FC<ILinkProps | IButtonProps> = (props) => {
  const rootCN = useMemo(() => {
    const {
      theme = 'white',
      size = 'sm',
      className,
      isDisabled,
      type
    } = props

    return cn(
      styles.root,
      className,
      { [styles.root__isDisabled]: isDisabled },
      { [styles[`root__type_${type}`]]: true },
      { [styles[`root__style_${theme}`]]: true },
      { [styles[`root__size_${size}`]]: true }
    )
  }, [props])

  if (props.type === 'button') {
    return (
      <div
        className={rootCN}
        onClick={props.onClick}
      >
        <div className={styles.root_text}>
          {props.text}
        </div>
      </div>
    )
  }

  if (props.type === 'link') {
    return (
      <a
        className={rootCN}
        target={props.target}
        href={props.linkUrl}
      >
        <div className={styles.root_text}>
          {props.text}
        </div>
      </a>
    )
  }

  return null
}
  
export default Button