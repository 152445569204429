import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
// import qs from 'qs'
import querystring from 'query-string'
import valueEqual from 'value-equal'
import axios from 'axios'

import { IRoom, Populated, TRoomMemberPopulated } from '@decibel-coin/decibel-sdk/lib/types/entities'
import {
  IListReqQuery,
  IListResBody,
  IListPagination,
  IListBaseField,
  IListFieldCollectionItem,
  IListField,
  IListSwitchField,
  IListRangeField,
  TListField,
  IGetRoomsReqQuery,
  IGetRoomMembersReqQuery
} from '@decibel-coin/decibel-sdk/lib/types/api'

import { ApiService } from 'services'

import {
  hasFieldValue,
  serializeField,
  resetField,
  updateField
} from 'components/Filters/fields'

const flatten = (items: any[]) => {
  return items.reduce((accum, item) => accum.concat(item), [])
}

const isEmptyObject = (obj) => {
  return Object.getOwnPropertyNames(obj).length === 0
}

interface IAntPaginationConfig {
  current?: number
  pageSize?: number
  total?: number
}

const defaultPaginationInfo = {
  page: 1,
  pages: 0,
  limit: 20,
  count: 0
}

const defaultQuery: IListReqQuery = {
  page: 1
}

type TMakeListReq = (query: IListReqQuery) => Promise<IListResBody>
type THandleListRes = (res: IListResBody) => void

const serializeFields = (newFields: TListField[]) => {
  return newFields
    .filter(field =>
      hasFieldValue(field)
    )
    .reduce((fieldsAcc, field) => ({
      ...fieldsAcc,
      ...serializeField(field)
    }), {})
}
const getQuery = (listPagination: IListPagination, fields: TListField[]): IListReqQuery => {
  return {
    page: listPagination.page,
    ...serializeFields(fields)
  }
}

const getSearch = (query: IListReqQuery) => {
  return `?${querystring.stringify(query)}`
}

const useList = (makeListRequest: TMakeListReq, handleListRequest: THandleListRes) =>  {
  const history = useHistory()
  const location = useLocation()

  const [fields, setFields] = useState<TListField[]>([])
  const [lastQuery, setLastQuery] = useState<IListReqQuery>(undefined)

  const [pagination, setPaginationInfo] = useState<IListPagination>(defaultPaginationInfo)

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const choices = useMemo<TListField[]>(() => {
    return fields
      .filter((field: TListField) =>
        hasFieldValue(field) && field.type !== 'List' && field.type !== 'Switch'
      )
  }, [fields])

  const isChoicesShown = useMemo(() => {
    return choices.length > 0
  }, [choices])
  
  const antPaginationConfig = useMemo<IAntPaginationConfig>(() => {
    return ({
      current: pagination.page,
      pageSize: pagination.limit,
      total: pagination.count
    })
  }, [pagination])

  const isPaginationShown = useMemo<boolean>(() => { 
    return pagination.pages > 1 && pagination.count > 0
  }, [pagination])

  const updateLocation = useCallback((query: IListReqQuery, silent = false) => {
    const isSearchChanged = getSearch(query) !== window.location.search

    if (isSearchChanged && !silent) {
    const search = getSearch(query)

      history.push({
        search,
        state: {
          type: 'list',
          query
        }
      })
    }
  }, [history])

  const load = useCallback(async (query: IListReqQuery, silent?: boolean) => {
    const newQuery: IListReqQuery = isEmptyObject(query)
      ? { ...defaultQuery }
      : { ...query }

    // const { isUserAuthorizedInApplication } = rootStore.accountStore

    // if (!isUserAuthorizedInApplication) {
    //   delete newQuery.my
    // }
    
    const queryIsNotChanged = valueEqual(
      lastQuery,
      Object.setPrototypeOf(newQuery, {})
    )

    if (queryIsNotChanged) {
      return
    }

    setIsLoading(true)

    setLastQuery(newQuery)

    try {
      const res = await makeListRequest(newQuery)

      const {
        fields: newFields,
        pagination: newPaginationInfo
      } = res

      setFields(newFields)
      setPaginationInfo(newPaginationInfo)
      updateLocation(getQuery(newPaginationInfo, newFields))

      handleListRequest(res)

      setIsLoading(false)
    } catch (error) {
      console.error(error)

      setIsLoading(false)
    }
  }, [makeListRequest, handleListRequest, updateLocation, lastQuery])


  useEffect(() => {
    const queryFromSearch = querystring.parse(location.search, {
      parseNumbers: true,
      parseBooleans: true
    }) as IListReqQuery

    console.log('queryFromSearch', queryFromSearch)

    load(queryFromSearch)
  }, [location.search])

  const handleUpdateFields = useCallback((newFields: TListField[]) => {
    load({
      page: 1,
      ...serializeFields(newFields)
    })
  }, [load])

  const handleUpdateField = useCallback((fieldToUpdate: TListField, payload: any) => {
    const newFields = fields.map((field: TListField) => {
      return field.name === fieldToUpdate.name
        ? updateField(fieldToUpdate, payload)
        : field
    })

    setFields(newFields)

    load({
      page: pagination.page,
      ...serializeFields(newFields)
    })
  }, [fields, pagination, load])

  const handleResetField = useCallback((fieldToUpdate: TListField) => {
    const newFields = fields.map((field: TListField) => {
      return field.name === fieldToUpdate.name
        ? resetField(fieldToUpdate)
        : field
    })

    load({
      page: pagination.page,
      ...serializeFields(newFields)
    })
  }, [fields, pagination, load])

  return {
    fields,
    pagination,
    isLoading,
    choices,

    isChoicesShown,
    antPaginationConfig,
    isPaginationShown,

    serializeFields,
    load,
    handleUpdateFields,
    handleUpdateField,
    handleResetField
  }
}

export {
  useList,

  IListReqQuery,
  IListResBody,
  TMakeListReq,
  THandleListRes
}