import React, { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import Media from 'react-media'

import useAuth from 'hooks/useAuth'
import useErrorHandler from 'hooks/useErrorHandler'

import { useAppDispatch } from 'state'
import { useAccount } from 'state/account/hooks'
import { getAccount } from 'state/account'

import { Button } from 'components'
import { ApiService } from 'services'
import { breakpoints } from 'utils'

import { ConnectorNames, walletLocalStorageKey, metamaskConnector, connectorLocalStorageKey } from 'utils/wallet'

import styles from './styles.module.scss'

interface IProps {
  className?: string
}

const ConnectMetamaskBtn: React.FC<IProps> = ({
  className
}) => {
  const { account: address, library } = useWeb3React()
  const { isAuthorized, isSignInPending, account, signInLocal, signOut } = useAccount()
  const history = useHistory()
  const { login } = useAuth()
  const dispatch = useAppDispatch()
  const { handleError } = useErrorHandler()

  const handleSignIn = async () => {
    history.push({
      pathname: `/signin`
    })
  }

  const handleBlockchainAccount = async () => {
    try {
      if (library && address && !account.blockchainAddress) {
        const message = `Welcome to Decibel!\n Click "Sign" to connect your Blockchain Account to Decibel profile`
        const signature = await library.send('personal_sign', [message, address])

        await ApiService.connectBlockchainAccount({
          address,
          signature
        })

        dispatch(getAccount())
      }
    } catch (error) {
      handleError(error)
    }
  }

  const handleConnectWallet = async () => {
    try {
      login(ConnectorNames.Injected)

      localStorage.setItem(walletLocalStorageKey, metamaskConnector.title);
      localStorage.setItem(connectorLocalStorageKey, metamaskConnector.connectorId); 
    } catch (error) {
      handleError(error)
    }
  }

  if (!account) {
    return null
  }

  return (
    <Media queries={{ xs: { maxWidth: breakpoints.xs } }}>
      {matches => (
        <div className={styles.root}>
          {/* {(!account) && (
            <Button
              type='button'
              onClick={() => handleSignIn()}
              className={styles.web3Account_action}
              text='Sign'
              theme='green'
              size={matches.xs ? 'xs' : 'sm' }
            />
          )} */}

          {(account && !account.blockchainAddress && address) && (
            <Button
              type='button'
              onClick={handleBlockchainAccount}
              className={styles.web3Account_action}
              text='Connect metamask account'
              theme='green'
              size={matches.xs ? 'xs' : 'sm' }
            />
          )}

          {(!address) && (
            <Button
              type='button'
              onClick={handleConnectWallet}
              className={cn(className)}
              text='Connect metamask'
              theme='green'
              size={matches.xs ? 'xs' : 'sm' }
            />
          )}
        </div>
      )}
    </Media>
  )
}

export default ConnectMetamaskBtn