import React, { Component } from 'react';
import cn from 'classnames';

import { IListField } from '@decibel-coin/decibel-sdk/lib/types/api'

import { SelectField } from 'components'

import styles from './styles.module.scss';

interface IProps {
  field: IListField
  onUpdateField: (value: string) => void
}

class ListField extends Component<IProps> {
  private handleChange = (value: string) => {
    const { onUpdateField } = this.props;

    onUpdateField(value);
  }

  render() {
    const { field } = this.props;

    return (
      <div className={styles.root}>
        <SelectField
          value={field.value}
          label={field.title}
          size={'xs'}
          options={field.collection.map(collectionItem => ({ value: collectionItem.value, title: collectionItem.title }))}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

const updateFn = (field: IListField, value: string): IListField => {
  return {
    ...field,
    value
  };
};

const resetFn = (field: IListField): IListField => {
  return {
    ...field,
    value: null
  };
};

const hasValueFn = (field: IListField): boolean => {
  return !!field.value;
};

const serializeFn = (field: IListField): Record<string, unknown> => {
  return {
    [field.name]: field.value
  };
};

export default ListField;

export {
  updateFn,
  resetFn,
  hasValueFn,
  serializeFn
};
