import React, { Component, useMemo, ReactNode }  from 'react'
import { useWeb3React } from '@web3-react/core'
import cn from 'classnames'
import BigNumber from 'bignumber.js'
import Media from 'react-media'

import { IRoom, Populated } from '@decibel-coin/decibel-sdk/lib/types/entities'

import { Link } from 'components'
import { formatTime, formatDBCToken, formatBasePoints, breakpoints } from 'utils'

import styles from './styles.module.scss'

interface IProps {
  room: Populated<IRoom, 'club'>
  actions?: ReactNode
}

const RoomDetails: React.FC<IProps> = ({
  room,
  actions
} ) => {
  const roomMembersCount = useMemo(() => {
    const { totalListenersCount, totalModeratorsCount, totalSpeakersCount } = room.metrics

    return totalListenersCount + totalModeratorsCount + totalSpeakersCount
  }, [room.metrics])

  const avRewardPerMember = useMemo(() => {
    return roomMembersCount > 0 
      ? new BigNumber(room.reward).div(roomMembersCount)
      : new  BigNumber(0)
  }, [room.reward, roomMembersCount])

  const formattedStartedTime = useMemo(() => {
    if (!room.startedTime) return undefined
    
    const date = new Date(room.startedTime)

    return new Intl.DateTimeFormat('en-US').format(date)
  }, [room])

  return (
    <Media queries={{ xs: { maxWidth: breakpoints.xs } }}>
      {matches => (
        <div className={cn(styles.root)}>
          <div className={styles.header}>
            <div className={styles.header_left}>          
              <Link
                className={styles.roomName}
                text={room.chRoom.name}
                hasIcon={!matches.xs}
                to={`/rooms/${room.id}`}
              />

              <div className={styles.clubName}>
                <span>from</span>

                <Link
                  text={room.club.chClub.name}
                  hasIcon={!matches.xs}
                  to={`/clubs/${room.club.id}`}
                />
              </div>
            </div>

            <div className={styles.header_right}>
              <div className={styles.status}>
                <div className={styles.status_title}>
                  Status:
                </div>
                
                <div className={styles.status_value}>
                  {room.state}
                </div>
              </div>

              {formattedStartedTime && (
                <div className={styles.date}>
                  <div className={styles.date_title}>
                    Date:
                  </div>

                  <div className={styles.date_value}>
                    {formattedStartedTime}
                  </div>
                </div>
              )}

              {actions && (
                <div className={styles.actions}>
                  {actions}
                </div>
              )}
            </div>
          </div>

          <div className={styles.content}>
            <div className={cn(styles.section, styles.section__reward)}>
              <div className={styles.section_title}>
                Reward data
              </div>

              <div className={styles.section_content}>
                <div className={styles.details}>
                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Pending reward
                    </div>

                    <div className={cn(styles.detail_value, styles.detail_value__highlighted)}>
                      {formatDBCToken(room.pendingReward)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Claimed reward
                    </div>

                    <div className={cn(styles.detail_value, styles.detail_value__highlighted)}>
                      {formatDBCToken(room.reward)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Average DBC reward per room member
                    </div>

                    <div className={styles.detail_value}>
                      {formatDBCToken(avRewardPerMember)}
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className={cn(styles.section, styles.section__reward)}>
              <div className={styles.section_title}>
                Staking data
              </div>

              <div className={styles.section_content}>
                <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Room members clubs staking amount
                    </div>

                    <div className={cn(styles.detail_value, styles.detail_value__highlighted)}>
                      {formatDBCToken(room.roomMembersStakingAmount)}
                    </div>
                  </div>
                </div>
              </div>


            <div className={cn(styles.section, styles.section__metrics)}>
              <div className={styles.section_title}>
                Room metrics
              </div>

              <div className={styles.section_content}>
                <div className={styles.details}>
                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Average moderator time
                    </div>

                    <div className={styles.detail_value}>
                      {formatTime(room.metrics.averageModeratorTime)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Average speaker time
                    </div>

                    <div className={styles.detail_value}>
                      {formatTime(room.metrics.averageSpeakerTime)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Average listener time
                    </div>

                    <div className={styles.detail_value}>
                      {formatTime(room.metrics.averageListenerTime)}
                    </div>
                  </div>
                </div>

                <div className={styles.details}>
                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Moderators count
                    </div>

                    <div className={styles.detail_value}>
                      {room.metrics.totalModeratorsCount}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Listeners count
                    </div>

                    <div className={styles.detail_value}>
                      {room.metrics.totalListenersCount}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Speakers count
                    </div>

                    <div className={styles.detail_value}>
                      {room.metrics.totalSpeakersCount}
                    </div>
                  </div>
                </div>
                
                <div className={styles.details}>
                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Stickiness
                    </div>

                    <div className={styles.detail_value}>
                      {formatBasePoints(room.metrics.stickiness)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Duration
                    </div>

                    <div className={styles.detail_value}>
                      {formatTime(room.metrics.durationTime)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Media>
  )
}

export default RoomDetails
