import React, { FC, ChangeEvent, useMemo } from 'react'
import cn from 'classnames'

import { Select } from 'components/antd'

import styles from './styles.module.scss'

type Theme = 'white' | 'blue' | 'blue-light' | 'green' | 'red' | 'gray-light'
type Size = 'xs' | 'sm' | 'md' | 'lg'

interface IProps {
  label?: string
  prefix?: string
  postfix?: string
  error?: string
  value: string
  className?: string
  isDisabled?: boolean
  size?: Size
  theme?: Theme
  options: { value: string, title: any }[]
  onChange: (value: any) => void
}

const SelectField: FC<IProps> = ({
  label,
  value,
  options,
  className,
  isDisabled,
  theme = 'white',
  size = 'sm',
  onChange,
  prefix,
  postfix,
  error
}) => {
  const rootCN = useMemo(() => {
    return cn(
      styles.root,
      className,
      { [styles.root__isDisabled]: isDisabled },
      { [styles[`root__style_${theme}`]]: true },
      { [styles[`root__size_${size}`]]: true }
    )
  }, [className, isDisabled, theme, size])

  return (
    <div className={rootCN} id={rootCN}>
      {label && (
        <div className={styles.section}>
          <div className={styles.label}>
            {label}
          </div>
        </div>
      )}

      <div className={styles.section}>
        {!!prefix && 
          <div className={styles.prefix}>
            {prefix}
          </div>
        }

        <div className={cn(styles.control, {
          [styles.control__hasError]: !!error
        })}>
          {/* <select
            value={value}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => onChange(event.target.value)}
          >
            {options.map(option => (
              <option value={option.value} key={option.value}>
                {option.title}
              </option>
            ))}
          </select> */}

          <Select
            value={value}
            className={cn(styles.select, 'ant-select-customize-input')}
            dropdownClassName={styles.select_dropdown}
            onChange={onChange}
            getPopupContainer={() => document.getElementById(rootCN)!}
            showSearch={false}
          >
            {options.map(option => (
              <Select.Option
                className={styles.select_option}
                value={option.value}
                key={option.value}
              >
                {option.title}
              </Select.Option>
            ))}
          </Select>
        </div>

        {!!postfix && 
          <div className={styles.postfix}>
            {postfix}
          </div>
        }
      </div>
    </div>
  )
}
  
export default SelectField