import React, { Component }  from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import cn from 'classnames'
import Media from 'react-media'

import DescriptionWithTx2 from 'components/Toast/DescriptionWithTx2'
import useToast from 'hooks/useToast'
import useAuth from 'hooks/useAuth'
import { useAppDispatch } from 'state'
import { getAccount } from 'state/account'
import { useAccount } from 'state/account/hooks'
import { FetchStatus, useGetDBCBalance } from 'hooks/useTokenBalance'

import { Button, ConnectMetamaskBtn, Avatar } from 'components'
import { formatDBCToken, formatAddress, breakpoints } from 'utils'
import { ApiService } from 'services'

import styles from './styles.module.scss'

const Header: React.FC = () => {
  const dispatch = useAppDispatch()
  
  const { toastSuccess, toastError } = useToast()
  const { account: address, library } = useWeb3React()
  const { balance, fetchStatus } = useGetDBCBalance()
  const { login, logout } = useAuth()
  const { isAuthorized, isSignInPending, account, signInLocal, signOut } = useAccount()

  const handleClickSignOut = async () => {
    await dispatch(signOut())
  }

  const handleConnectWallet = async () => {
  }

  const handleBlockchainAccount = async () => {
    if (library && address && !account.blockchainAddress) {
      const message = `Welcome to Decibel!\n Click "Sign" to connect your Blockchain Account to Decibel profile`
      const signature = await library.send('personal_sign', [message, address])

      await ApiService.connectBlockchainAccount({
        address,
        signature
      })

      dispatch(getAccount())
    }
  }

  const formatText = (text: string) => {
    return `${text.substring(0, 6)}...`
  } 

  return (
    <Media queries={{ xs: { maxWidth: breakpoints.xs } }}>
      {matches => (
        <div className={cn(styles.root, styles.layout)}>
          <div className={styles.layout_left}>
            <a
              href='https://decibelcoin.com/'
              target='_blank'
              rel='noreferrer'
              className={styles.fullLogo}
            >
              <div className={styles.fullLogo_logo1}/>
              <div className={styles.fullLogo_logo2}/>
            </a>
          </div>

          <div className={styles.layout_right}>
            <div className={styles.web3Account}>
              {
                (address)
                  ? (
                    <div className={cn(styles.web3Account_authorized, styles.web3AccountAuthorized)}>
                      <div className={styles.web3AccountAuthorized_balance}>
                        <div>Balance: </div>

                        <span>
                          {fetchStatus === FetchStatus.SUCCESS ?
                            `${formatDBCToken(balance)}`
                            : '...'
                          } 
                        </span>
                      </div>
                      <div className={styles.web3AccountAuthorized_address}>
                        {formatAddress(address)}
                      </div>
                    </div>
                  )
                  : (
                    <div className={styles.web3Account_actions}>
                      {/* {(!address) && (
                        <ConnectMetamaskBtn/>
                      )} */}
                    </div>
                  )
              }
            </div>

            <div className={styles.account}>
              {account && (
                <NavLink
                  to='/account'
                  className={styles.accountAuthorized}
                  activeClassName={styles.accountAuthorized__isActive}
                >
                  {account.chAccount
                      ? (
                        <>
                          <div className={cn(styles.accountAuthorized_avatar, styles.avatar)}>
                            <Avatar
                              image={account.chAccount.photoUrl}
                              size={matches.xs ? 'xxs' : 'xs'}
                            />
                          </div>


                          <div className={styles.accountAuthorized_username}>
                            {formatText(account.chAccount.username)}
                          </div>
                        </>
                      )
                      : (
                        <div className={styles.accountAuthorized_email}>
                          {formatText(account.email)}
                        </div>
                      )
                    }
                </NavLink>
              )}

              <div className={styles.account_actions}>
                {account
                  ? (
                    <div
                      className={cn(styles.account_action, styles.account_action__signOut)}
                      onKeyUp={() => {}}
                      role='button'
                      tabIndex={0}
                      onFocus={() => {}}
                      onClick={handleClickSignOut}
                    >
                      sign out
                    </div>
                  )
                  : (
                    <>
                      <NavLink
                        exact
                        to='/signin'
                        className={cn(styles.account_action, styles.account_action__signIn)}
                        activeClassName={styles.account_action__isActive}
                      >
                        sign in
                      </NavLink>

                      <NavLink
                        exact
                        to='/signup'
                        className={cn(styles.account_action, styles.account_action__signUp)}
                        activeClassName={styles.account_action__isActive}
                      >
                        sign up
                      </NavLink>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </Media>
  )
}

export default Header
