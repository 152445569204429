import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import { CSSTransition } from 'react-transition-group'
import { variants } from 'utils/uikit'
import cn from 'classnames'

import { Box } from 'components'
import { ToastProps, types } from './types'

import styles from './styles.module.scss'

const alertTypeMap = {
  [types.INFO]: variants.INFO,
  [types.SUCCESS]: variants.SUCCESS,
  [types.DANGER]: variants.DANGER,
  [types.WARNING]: variants.WARNING,
}

const Toast: React.FC<ToastProps> = ({ toast, onRemove, style, ttl, ...props }) => {
  const timer = useRef<number>()
  const ref = useRef(null)
  const removeHandler = useRef(onRemove)
  const { id, title, description, type } = toast

  const handleRemove = useCallback(() => removeHandler.current(id), [id, removeHandler])

  const handleMouseEnter = () => {
    clearTimeout(timer.current)
  }

  const handleMouseLeave = () => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = window.setTimeout(() => {
      handleRemove()
    }, ttl)
  }

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = window.setTimeout(() => {
      handleRemove()
    }, ttl)

    return () => {
      clearTimeout(timer.current)
    }
  }, [timer, ttl, handleRemove])

  const rootCN = useMemo(() => {
    const theme = alertTypeMap[type]

    return cn(
      styles.root,
      { [styles[`root__type_${theme}`]]: true },
    )
  }, [type])

  return (
    <CSSTransition nodeRef={ref} timeout={250} style={style} {...props}>
      <div
        ref={ref}
        className={rootCN}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box className={styles.root_box}>
          <div className={styles.root_title}>{title}</div>
          <div className={styles.root_description}>{description}</div>

          <div
            className={styles.root_closeBtn}
            onClick={() => handleRemove()}
          >
            ✕
          </div>
        </Box>
      </div>
    </CSSTransition>
  )
}

export default Toast
