import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'

import { ethersToBigNumber } from '@decibel-coin/decibel-contracts/lib/contract-utils'

import { useMasterContract }  from 'hooks/useContract'

import tokens from 'config/constants/tokens'
import { getBep20Contract } from 'utils/contractHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { simpleRpcProvider } from 'utils/providers'
import useRefresh from './useRefresh'
import useLastUpdated from './useLastUpdated'

import { FetchStatus } from './useTokenBalance'

type UseTokenBalanceState = {
  balance: BigNumber
  fetchStatus: FetchStatus
}

// export enum FetchStatus {
//   NOT_FETCHED = 'not-fetched',
//   SUCCESS = 'success',
//   FAILED = 'failed',
// }

const useClubStakingPoolBalance = (clubId: number) => {
  const { NOT_FETCHED, SUCCESS, FAILED } = FetchStatus
  const [balanceState, setBalanceState] = useState<UseTokenBalanceState>({
    balance: BIG_ZERO,
    fetchStatus: NOT_FETCHED,
  })
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()

  const masterContract = useMasterContract()

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const stakingPoolMember = await masterContract.stakingPoolMembers(clubId, account)

        setBalanceState({ balance: ethersToBigNumber(stakingPoolMember.stakeAmount), fetchStatus: SUCCESS })
      } catch (e) {
        console.error(e)
        setBalanceState((prev) => ({
          ...prev,
          fetchStatus: FAILED,
        }))
      }
    }

    if (account) {
      fetchBalance()
    }
  }, [account, fastRefresh, SUCCESS, FAILED, masterContract, clubId])

  return balanceState
}

export {
  FetchStatus,
  useClubStakingPoolBalance
}
