import { serializeError } from 'eth-rpc-errors'

import { ethRpcErrorCodeNameMap, ethRpcErrorCodeDescriptionMap } from '@decibel-coin/decibel-contracts/lib'

import useToast from 'hooks/useToast'

import { isApiAppError } from 'services/Api'

const useErrorHandler = () => {
  const { toasts, remove, toastError, toastSuccess } = useToast()

  const handleError = (error: Error | any) => {
    if (isApiAppError(error)) {
      return toastError(
        'Api error',
        error.message
      )
    }

    if (!error.code) {
      return toastError(
        'Error',
        error?.message || 'Unknow error'
      )
    }

    const serializedEthRPCError = serializeError(error)
    const serializedContractError = serializedEthRPCError.data
      ? serializeError(serializedEthRPCError.data)
      : undefined 

    if (serializedContractError && serializedContractError.code) {
      const name = ethRpcErrorCodeNameMap[serializedContractError.code] || 'Unknow error name'

      const formattedMessage = serializedContractError.message.replace('VM Exception while processing transaction: revert', '')

      const message =  formattedMessage || serializedContractError.message

      return toastError(
        name,
        message
      )
    }
    
    if (serializedEthRPCError && serializedEthRPCError.code) {
      const name = ethRpcErrorCodeNameMap[serializedEthRPCError.code] || 'Unknow error name'
      const message = ethRpcErrorCodeDescriptionMap[serializedEthRPCError.code] || serializedEthRPCError.message

      return toastError(
        name,
        message 
      )
    } 

    console.error(error)

    toastError(
      'Error',
      error?.message || 'Unknow error'
    )
  }

  return {
    handleError
  }
}

export default useErrorHandler
