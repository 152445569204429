import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

interface IProps {}

interface ILink {
  name: 'clubhouse' | 'github' | 'bscscan' | 'telegram' | 'twitter' | 'instagram'
  url: string
}

const links: ILink[] = [
  {
    name: 'clubhouse',
    url: 'https://www.clubhouse.com/club/decibel'
  }, 
  {
    name: 'github',
    url: 'https://github.com/DecibelCoin'
  },
  {
    name: 'bscscan',
    url: 'https://bscscan.com/address/0x46aA2AA8105cA11EB8E6BF12506B47255FBc1f5A#code'
  },
  {
    name: 'telegram',
    url: 'https://t.me/decibelcoin'
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/decibelcoin'
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/decibelcoin'
  }
]

const Footer: React.FC<IProps> = () => {
  return (
    <div className={styles.root}>
      <div className={styles.root_section}>
        <div className={styles.links}>
          {links.map(link => (
            <a
              key={link.name}
              className={cn(
                styles.link,
                styles[`link__${link.name}`]
              )}
              target='_blank'
              rel='noreferrer'
              href={link.url}
            />
          ))}
        </div>
      </div>

      <div className={styles.root_section}>
        <div className={styles.links}>
            <a
              key='phone'
              className={cn(styles.link, styles.link__phone)}
              target='_blank'
              rel='noreferrer'
              href='#'
            />

            <a
              key='faq'
              className={cn(styles.link, styles.link__faq)}
              target='_blank'
              rel='noreferrer'
              href='#'
            />
        </div>

        <div className={styles.logo}/>
      </div>
    </div>
  )
}

export default Footer