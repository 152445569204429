import React, { Component } from 'react'
import cn from 'classnames'

import { IListSwitchField } from '@decibel-coin/decibel-sdk/lib/types/api'

import { SwitchField } from 'components'

import styles from './styles.module.scss';

interface IProps {
  field: IListSwitchField
  onUpdateField: (value: boolean) => void
}

class RangeField extends Component<IProps> {
  private handleChange = (value: boolean) => {
    const { onUpdateField } = this.props

    onUpdateField(value)
  }

  render() {
    const {
      field
    } = this.props

    return (
      <div className={styles.root}>
        <SwitchField
          value={field.value}
          postfix={field.title}
          size={'xs'}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

const updateFn = (field: IListSwitchField, value: boolean): IListSwitchField => {
  return {
    ...field,
    value
  }
}

const resetFn = (field: IListSwitchField): IListSwitchField => {
  return {
    ...field,
    value: false
  }
}

const hasValueFn = (field: IListSwitchField): boolean => {
  return field.value
}

const serializeFn = (field: IListSwitchField): Record<string, unknown> => {
  return {
    [field.name]: field.value
  }
}

export default RangeField

export {
  updateFn,
  resetFn,
  hasValueFn,
  serializeFn
}
