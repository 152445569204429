import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { useDispatch } from 'react-redux'
import blockReducer from './block'
import { updateVersion } from './global/actions'
import multicall from './multicall/reducer'
import accountReducer from './account/index'
import uiReducer from './ui/index'

const PERSISTED_KEYS: string[] = []
const NOT_PERSISTED_KEYS: string[] = ['ui', 'account', 'block', 'multicall']

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    ui: uiReducer,
    account: accountReducer,
    block: blockReducer,
    multicall,
  },
  middleware: [...getDefaultMiddleware({ thunk: true }), save({ states: PERSISTED_KEYS, ignoreStates: NOT_PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

store.dispatch(updateVersion())

export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch()

export default store
