import React, { useState, useMemo } from 'react'
import cn from 'classnames'

import { IClub} from '@decibel-coin/decibel-sdk/lib/types/entities'
import { clubhouseBots } from '@decibel-coin/decibel-sdk/lib/utils/clubhouse'

import useToast from 'hooks/useToast'
import useErrorHandler from 'hooks/useErrorHandler'

import { Modal } from 'components/antd'
import { Button } from 'components'
import { ApiService } from 'services'

import styles from './styles.module.scss'

interface IProps {
  club: IClub
  isOpened: boolean
  onClose: (isSuccess?: true) => void
}

const GetClubInviteModal: React.FC<IProps> = ({
  isOpened,
  club,
  onClose,
}) => {
  const { toastSuccess, toastError } = useToast()
  const { handleError } = useErrorHandler()

  const [isPending, setIsPending] = useState(false)

  const handleGetInvite = async () => {
    try {
      setIsPending(true)

      await ApiService.getClubInvite({
        clubId: club.id
      })

      toastSuccess('Invite was sent')

      setIsPending(false)
      
      onClose(true)
    } catch (error) {
      setIsPending(false)
      handleError(error)
    }
  }

  const clubhouseBot = useMemo(() => {
    return clubhouseBots.find(bot => bot.username === club.chBot.username)
  }, [club])


  return (
    <Modal
      className={styles.modal}
      wrapClassName={styles.modalWrap}
      closable={false}
      centered
      visible={isOpened}
      footer={null}
      onCancel={() => onClose()}

    >
      <div className={styles.root}>
        <div className={styles.header}>
          Get access to the club via invitation
        </div>

        <div className={styles.content}>
          <div className={styles.section}>
            <div className={styles.instruction}>
              <div className={styles.instruction_title}>
                <div>Follow next steps:</div>
              </div>
              <div className={styles.instruction_text}>
                <div>
                  1 - Open Clubhouse mobile app
                </div>
                <div>
                  2 - Follow
                  <a
                    className={styles.instruction_link}
                    href={`https://joinclubhouse.com/@${clubhouseBot.username}`}
                    rel='noreferrer'
                    target='_blank'
                  >
                    {clubhouseBot.name}
                  </a>
                  account from your account
                </div>

                <div>3 - After following {clubhouseBot.name} account, wait 10 seconds and click <div className={styles.instruction_highlight}>Get invite</div> button</div>
                <span>* After receiving the invitation, you can unfollow {clubhouseBot.name} account</span>
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.actions}>
              <Button
                type='button'
                className={styles.actions_action}
                onClick={handleGetInvite}
                theme='green'
                size='sm'
                text='Get invite'
                isDisabled={isPending}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default GetClubInviteModal
