import { createBrowserHistory } from 'history'

import { getEnvVar } from '@decibel-coin/decibel-sdk/lib/utils'

// Manually create the history object so we can access outside the Router e.g. in modals
const history = createBrowserHistory({
    basename: getEnvVar('PUBLIC_URL')
})

export default history
