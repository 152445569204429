import { useEffect, useMemo, useCallback} from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'

import { useAppDispatch } from 'state'

import { simpleRpcProvider } from 'utils/providers'

import useRefresh from 'hooks/useRefresh'
import { State, AccountState } from '../types'
import { signInLocal, signInJwt, signOut, signUp } from '.'

export const useAccount = () => {
  const { account, isAuthorized, isSignInPending, isSignOutPending, isSignUpPending }: AccountState = useSelector((state: State) => state.account)

  return {
    account, isAuthorized, isSignInPending, isSignOutPending, isSignUpPending,
    signInLocal, signInJwt, signOut, signUp
  }
}