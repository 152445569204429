import React from 'react'
import { getBscScanLink } from 'utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import truncateHash from 'utils/truncateHash'

import { ExternalLink } from 'components'

interface DescriptionWithTxProps {
  description?: string
  txHash?: string
}

const DescriptionWithTx2: React.FC<DescriptionWithTxProps> = ({ txHash, children }) => {
  const { chainId } = useActiveWeb3React()

  return (
    <>
      <div>
        {typeof children === 'string'
          ? (<div>{children}</div>)
          : children
        }
      </div>


      {txHash && (
        <div>
          <ExternalLink
            to={getBscScanLink(txHash, 'transaction', chainId)}
            target='_blank'
            text={`View on BscScan: ${truncateHash(txHash, 8, 0)}`}
          />
        </div>
      )}
    </>
  )
}

export default DescriptionWithTx2
