import React  from 'react'
import { Switch } from 'react-router'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import Header from 'components/Header'
import Footer from 'components/Footer'

import { Navbar } from 'components'

import styles from './styles.module.scss'


interface IProps {}

const Layout: React.FC<IProps> = ({ children }) => {
  return (
    <div className={styles.defaultLayout}>
      <div className={styles.defaultLayout_header} >
        <div className={cn(styles.defaultLayout_container, 'container__fluid')}>
          <Header/> 
        </div>

        <div className={cn(styles.defaultLayout_container, 'container__fluid')}>
          <Navbar/>
        </div>
      </div>

      <div className={styles.defaultLayout_content}>
        <div className={cn(styles.defaultLayout_container, 'container__fluid')}>
          {children}
        </div>
      </div>

      <div className={styles.defaultLayout_footer}>
        <div className={cn(styles.defaultLayout_container, 'container__fluid')}>
          <Footer/>
        </div>
      </div>
    </div>
  )
}

export default Layout
