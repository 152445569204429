import React, { FC, useMemo } from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg'

interface IProps {
  image?: string | undefined
  className?: string
  size?: Size
}

const Avatar: FC<IProps> = ({
  image,
  size = 'xs',
  className,
}) => {
  const rootCN = useMemo(() => {
    return cn(
      styles.root,
      className,
      { [styles[`root__size_${size}`]]: true }
    )
  }, [className, size])
  
  return (
  <div className={rootCN}>
    {image
      ? (<img className={styles.image} src={image} alt=''/>)
      : (<div className={styles.placeholder}/>)
    }
  </div>
)}
  
export default Avatar