import React from 'react'
import cn from 'classnames'

import useAuth from 'hooks/useAuth'

import { Button } from 'components'

import { connectorLocalStorageKey } from 'utils/wallet'

interface IProps {
  className?: string
}

const DisconnectMetamaskBtn: React.FC<IProps> = ({
  className
}) => {
  const { logout } = useAuth()

  const handleDisconnectWallet = () => {
    logout()
    window.localStorage.removeItem(connectorLocalStorageKey);
  }

  return (
    <Button
      type='button'
      onClick={handleDisconnectWallet}
      className={cn(className)}
      text='Disconnect metamask'
      theme='red'
      size='xs'
    />
  )
}

export default DisconnectMetamaskBtn