import React, { useMemo, useCallback } from 'react'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { match as IMatch, matchPath } from 'react-router'
import Media from 'react-media'
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import cn from 'classnames'

import { useUI } from 'state/ui/hooks'

import { SelectField } from 'components'
import { breakpoints } from 'utils'

import styles from './styles.module.scss'

const Navbar: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const { breadcrumb } = useUI()

  const getClubRouteTitle = useCallback((
    { match: matchProp }: { match: IMatch<{clubId: string}>}
  ) => {
    if (!breadcrumb || !breadcrumb.clubs) return 'Club'

    return breadcrumb.clubs[matchProp.params.clubId]?.title || 'Club'
  }, [breadcrumb])

  const getRoomRouteTitle = useCallback((
    { match: matchProp }: { match: IMatch<{roomId: string}>}
  ) => {
    if (!breadcrumb || !breadcrumb.rooms) return 'Room'

    return breadcrumb.rooms[matchProp.params.roomId]?.title || 'Room'
  }, [breadcrumb])

  const routes = useMemo(() => {
    return [
      { path: "/free-bnb", title: "Get free BNB", isMenuRoute: true },
      { 
        isMenuRoute: true,
        path: "/connect-club",
        title: "Connect Club" 
      },
      // {
      //   isMenuRoute: true,
      //   path: "/",
      //   title: "Home"
      // },
      { 
        isMenuRoute: true,
        path: "/account",
        title: "Account"
      },
      {
        isMenuRoute: true,
        path: "/clubs",
        title: "Clubs"
      },
      { 
        path: "/clubs/:clubId",
        title: getClubRouteTitle
      },
      {
        isMenuRoute: true,
        path: "/rooms",
        title: "Rooms"
      },
      { 
        path: "/rooms/:roomId",
        title: getRoomRouteTitle
      },
      { path: "/signin", title: "Signin" },
      { path: "/signup", title: "Signup" },
      { path: "/connect-account", title: "Connect Account" },
      { path: "/connect-blockchain-account", title: "Connect Blockchain Account" },
      { path: "/verify-email", title: "Verify Email" },
    ];
  }, [getClubRouteTitle, getRoomRouteTitle])

  const breadcrumbRoutes = useMemo(() => {
    return routes.map(route => ({
      ...route,
      breadcrumb: route.title
    })) as any as BreadcrumbsRoute[]
  }, [routes])

  const menuRoutes = useMemo(() => {
    return routes.filter(route => route.isMenuRoute)
  }, [routes])

  const currentMenuItemPath = useMemo(() => {
    return [...menuRoutes].reverse().find(route => {
      const currentMatch = matchPath(location.pathname, {
        path: route.path,
        exact: false,
        strict: false
      })

      return !!currentMatch
    })
  }, [menuRoutes, location])

  const handleChangeRoute = (path: string) => {
    if (path === '/free-bnb') {
      window.open('https://testnet.binance.org/faucet-smart')
    } else {
      history.push(path)
    }
  }

  const breadcrumbs = useBreadcrumbs(breadcrumbRoutes)

  return (
    <div className={styles.root}>
      <div className={styles.root_left}>
        <div className={styles.breadcrumbs}>
            {breadcrumbs.map(breadcrumbData => (
              <span
                key={breadcrumbData.match.url}
                className={styles.breadcrumb}
              >
                <span className={styles.breadcrumb_separator}/>

                <NavLink
                  to={breadcrumbData.match.url}
                  exact
                  className={styles.breadcrumb_link}
                  activeClassName={styles.breadcrumb_link__isActive}
                >
                  {typeof breadcrumbData.breadcrumb === 'string'
                    ? breadcrumbData.breadcrumb
                    : <div>{breadcrumbData.breadcrumb}</div>
                  }
                </NavLink>
              </span>
            ))}
          </div>
      </div>
      
      <div className={styles.root_right}>
        <div className={styles.menu}>
          <Media queries={{ xs: { maxWidth: breakpoints.xs } }}>
            {matches => (
              <SelectField
                label='Menu'
                value={currentMenuItemPath?.path || '---'}
                className={styles.menu_select}
                size={matches.xs ? 'xs' : 'sm'}
                options={menuRoutes.map(route => ({ value: route.path, title: route.title }))}
                onChange={handleChangeRoute}
              />
            )}
          </Media>
        </div>
      </div>
    </div>
  )
}

export default Navbar