import React, { useMemo }  from 'react'
import cn from 'classnames'
import BigNumber from 'bignumber.js'

import { IClubMember, IClub, Populated } from '@decibel-coin/decibel-sdk/lib/types/entities'

import { Box, Avatar } from 'components'
import { formatDBCToken, formatPercent } from 'utils'


import styles from './styles.module.scss'

interface IProps {
  club: IClub
  clubMember: Populated<IClubMember, 'account'>
  className?: string
}

const ClubMemberPreview: React.FC<IProps> = ({
  club,
  clubMember,
  className
} ) => {
  const { account } = clubMember
  const chAccount = account.chAccount

  const rewardShare = useMemo(() => {
    const roomRewardBN = new BigNumber(club.metrics.totalReward)
    const roomMemberRewardBN = new BigNumber(clubMember.totalReward)
    return formatPercent
  }, [club, clubMember])

  const avRewardPerRoom = useMemo(() => {
    return (clubMember && clubMember.roomsCount > 0)
      ? new BigNumber(clubMember.totalReward).div(clubMember.roomsCount)
      : new  BigNumber(0)
  }, [clubMember])

  return (
    <Box className={cn(styles.root, className)}>
      <div className={styles.header}>
        <div className={cn(styles.header_section, styles.header_section__1)}>
          <div className={styles.userInfo}>
            <div className={styles.avatar}>
              <Avatar image={clubMember.account.chAccount.photoUrl} size='sm'/>
            </div>

            <div className={styles.names}>
              <div className={styles.names_name}>
                {chAccount.name}
              </div>

              <div className={styles.names_username}>
                <a
                  className={styles.social_link}
                  target='_blank'
                  rel='noreferrer'
                  href={`https://joinclubhouse.com/@${chAccount.username}`}
                >
                  @{chAccount.username}
                </a>
              </div>
            </div>
          </div>

          <div className={styles.socials}>
            {chAccount.instagram && (
              <div className={cn(styles.social, styles.social__instagram)}>
                <div className={styles.social_icon}/>

                <a
                  className={styles.social_link}
                  target='_blank'
                  rel='noreferrer'
                  href={`https://www.instagram.com/${chAccount.instagram}`}
                >
                  instagram
                </a>
              </div>
            )}

            {chAccount.twitter && (
              <div className={cn(styles.social, styles.social__twitter)}>
                <div className={styles.social_icon}/>

                <a
                  className={styles.social_link}
                  target='_blank'
                  rel='noreferrer'
                  href={`https://twitter.com/${chAccount.twitter}`}
                >
                  twitter
                </a>
              </div>
            )}
          </div>
        </div>
        {/* 
        <div className={styles.header_right}>
          <div className={styles.status}>
            <div className={styles.status_title}>
              Role:
            </div>

            <div className={styles.status_value}>
              {clubMember.lastLog.role}
            </div>
          </div>
        </div> */}
      </div>

      <div className={styles.content}>
        <div className={cn(styles.section, styles.section__reward)}>
          <div className={styles.section_title}>
            Reward data
          </div>

          <div className={styles.section_content}>
            <div className={styles.details}>
              <div className={styles.detail}>
                <div className={styles.detail_name}>
                  Claimed reward
                </div>

                <div className={styles.detail_value}>
                  {formatDBCToken(new BigNumber(clubMember?.totalReward || 0))}
                </div>
              </div>


              <div className={styles.detail}>
                <div className={styles.detail_name}>
                  Farmed rooms count
                </div>

                <div className={styles.detail_value}>
                  {clubMember?.roomsCount || 0}
                </div>
              </div>

              <div className={styles.detail}>
                <div className={styles.detail_name}>
                  Average reward per room
                </div>

                <div className={styles.detail_value}>
                  {formatDBCToken(avRewardPerRoom)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={cn(styles.section, styles.section__staking)}>
          <div className={styles.section_title}>
            Staking data
          </div>

          <div className={styles.section_content}>
            <div className={styles.details}>
              <div className={styles.detail}>
                <div className={styles.detail_name}>
                  Staking amount
                </div>

                <div className={styles.detail_value}>
                  {formatDBCToken(new BigNumber(clubMember?.staking || 0))}
                </div>
              </div>

              <div className={styles.detail}>
                <div className={styles.detail_name}>
                  Staking share
                </div>

                <div className={styles.detail_value}>
                  {formatPercent(clubMember?.staking || 0, club.metrics.totalStaking)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={cn(styles.section, styles.section__bio)}>
          <div className={styles.section_title}>
            Bio
          </div>

          <div className={styles.section_content}>
            <div className={styles.info}>
              <div className={styles.bio}>
                {chAccount.bio}
              </div>

              <div className={styles.stats}>
                <div className={styles.stat}>
                  <div className={styles.stat_value}>
                    {chAccount.numFollowers}
                  </div>

                  <div className={styles.stat_label}>
                    followers
                  </div>
                </div>

                <div className={styles.stat}>
                  <div className={styles.stat_value}>
                    {chAccount.numFollowing}
                  </div>

                  <div className={styles.stat_label}>
                    following
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default ClubMemberPreview
