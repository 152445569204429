import Menu from 'antd/lib/menu'
import Breadcrumb from 'antd/lib/breadcrumb'
import Checkbox from 'antd/lib/checkbox'
import Dropdown from 'antd/lib/dropdown'
import DatePicker from 'antd/lib/date-picker'
import Collapse from 'antd/lib/collapse'
import Slider from 'antd/lib/slider'
import Carousel from 'antd/lib/carousel'
import Switch from 'antd/lib/switch'
import Modal from 'antd/lib/modal'
import Table from 'antd/lib/table'
import Pagination from 'antd/lib/pagination'
import Select from 'antd/lib/select'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import InputNumber from 'antd/lib/input-number'
import Button from 'antd/lib/button'
import Tooltip from 'antd/lib/tooltip'
import Steps from 'antd/lib/steps'
import Progress from 'antd/lib/progress'
import Popover from 'antd/lib/popover'
import notification from 'antd/lib/notification'

export {
  Breadcrumb,
  Form,
  Checkbox,
  DatePicker,
  Input,
  Button,
  Menu,
  Dropdown,
  notification,
  Collapse,
  Slider,
  InputNumber,
  Switch,
  Modal,
  Table,
  Pagination,
  Select,
  Tooltip,
  Progress,
  Steps,
  Carousel,
  Popover
}
