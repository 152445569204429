import axios, { AxiosError } from 'axios'
import qs from 'qs'

import { getEnvVar, getEnv } from '@decibel-coin/decibel-sdk/lib/utils'

import {
  // Club
  IGetClubBySlugParams,
  IGetClubBySlugRes,
  IGetClubParams,
  IGetClubRes,

  // ClubMembers
  IGetClubMemberReqParams,
  IGetClubMemberResBody,
  IGetClubMembersReqQuery,
  IGetClubMembersResBody,
  IBecomeClubMemberReqBody,
  IBecomeClubMemberResBody,
  IVerifyClubMemberMembershipReqBody,
  IVerifyClubMemberMembershipResBody,
  IVerifyClubMemberStakeReqParams,
  IVerifyClubMemberStakeResBody,

// Auth
  ISignUpRes,
  ISignInLocalBody,
  ISignInLocalRes,
  ISignInJwtRes,
  ISignUpBody,
  IVerifyClubhouseAccountBody,
  IVerifyClubhouseAccountRes,
  IVerifyClubBody,
  IVerifyClubRes,
  IGetClubsReqQuery,
  IGetClubsResBody,

  // Rooms
  IGetRoomReqParams,
  IGetRoomResBody,
  IGetRoomsReqQuery,
  IGetRoomsResBody,
  IStartRoomFarmingReqParams,
  IStartRoomFarmingResBody,
  IStopRoomFarmingReqParams,
  IStopRoomFarmingResBody,

  // RoomMember
  IGetRoomMembersReqQuery,
  IGetRoomMembersResBody,
  IGetRoomMemberReqParams,
  IGetRoomMemberResBody,
  IVerifyRoomMemberRewardReqParams,
  IVerifyRoomMemberRewardResBody,

  IGetRoomByChannelIdReqParams,
  IGetRoomByChannelResBody,
  IAcceptBotInvitationReqBody,
  IAcceptBotInvitationResBody,
  IVerifyBotLeaderRoleReqBody,
  IVerifyBotLeaderRoleResBody,
  IVerifyContractConnectionReqBody,
  IVerifyContractConnectionResBody,
  IChangeClubStatusParams,
  IChangeClubStatusBody,
  IChangeClubStatusRes,
  IGetClubInviteBody,
  IGetClubInviteRes,
  IConnectClubBody,
  IConnectClubRes,
  
  IGetAccountReqParams,
  IGetAccountResBody,
  IConnectBlockchainAccountBody,
  IConnectBlockchainAccountRes,
  IDisconnectBlockchainAccountBody,
  IDisconnectBlockchainAccountRes,
  IConnectClubhouseAccountRes,
  IConnectClubhouseAccountBody,

  ICancelClubSubscriptionParams,
  ICancelClubSubscriptionRes,
  IGetAddClubMessageSignatureReqBody,
  IGetAddClubMessageSignatureResBody,
  IGetAddRoomMessageSignatureReqBody,
  IGetAddRoomMessageSignatureResBody,
  IGetGetRoomMemberRewardMessageSignatureReqBody,
  IGetGetRoomMemberRewardMessageSignatureResBody,
  ISubscribeOnClubMessageSignatureReqBody,
  ISubscribeOnClubMessageSignatureResBody,
} from '@decibel-coin/decibel-sdk/lib/types/api'

class ApiAppError extends Error {
  constructor(message: string) {
    super(message)

    this.name = "ApiAppError"

    Object.setPrototypeOf(this, new.target.prototype)
    
    Error.captureStackTrace(this)
  }
}

const isApiAppError = (maybeApiAppError: Error | ApiAppError | any): maybeApiAppError is ApiAppError => {
  if (maybeApiAppError instanceof ApiAppError) {
    return true;
  }
  return false;
}

const isAxiosError = (error: AxiosError | Error): error is AxiosError => 
  !!((error as AxiosError)?.response?.data)

const handleError = (error: AxiosError | Error) => {
  console.error(error)
  const errorMessage = isAxiosError(error) ? (error.response?.data.error || error.message) : error.message

  const formattedError = new ApiAppError(errorMessage as string)

  throw(formattedError)
}

const apiAxios = getEnvVar('NODE_ENV') === 'production' 
  ? axios.create({
    baseURL: `${getEnvVar('APP_PROTOCOL')}://${getEnvVar('APP_HOST')}`,
    withCredentials: true
  })
  : axios.create({
    baseURL: `${getEnvVar('APP_PROTOCOL')}://${getEnvVar('APP_HOST')}`,
    withCredentials: true
  })

const getClubBySlug = (params: IGetClubBySlugParams) => 
  apiAxios.get<IGetClubBySlugRes>(`/clubs/by_slug/${params.slug}`)
    .then(res => res.data.club)
    .catch((error: AxiosError) => handleError(error))

const getClub = (params: IGetClubParams) => 
  apiAxios.get<IGetClubRes>(`/clubs/${params.id}`)
    .then(res => res.data.club)
    .catch((error: AxiosError) => handleError(error))

const getClubs = (query: IGetClubsReqQuery) =>
  apiAxios.get<IGetClubsResBody>('/clubs', {
    params: query
  })
    .then(res => res.data)
    .catch((error: AxiosError) => handleError(error))

const getClubInvite = (body: IGetClubInviteBody) =>
  axios.post<IGetClubInviteRes>(`/clubs/invite`, {
    clubId: body.clubId,
  })

// ClubMembers
const getClubMember = (params: IGetClubMemberReqParams) => 
  apiAxios.get<IGetClubMemberResBody>(`/club-members/${params.id}`)
    .then(res => res.data.clubMember)
    .catch((error: AxiosError) => handleError(error))

const getClubMembers = (query: IGetClubMembersReqQuery) =>
  apiAxios.get<IGetClubMembersResBody>('/club-members', {
    params: query
  })
    .then(res => res.data.clubMembers)
    .catch((error: AxiosError) => handleError(error))
    
const becomeClubMember = (body: IBecomeClubMemberReqBody) =>
  apiAxios.post<IBecomeClubMemberResBody>('/club-members/', {
    clubId: body.clubId,
  })
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))

const verifyClubMemberMembership = (body: IVerifyClubMemberMembershipReqBody) =>
  apiAxios.post<IVerifyClubMemberMembershipResBody>('/club-members/verify-membership', {
    clubId: body.clubId,
  })
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))
    
const verifyClubMemberStake = (params: IVerifyClubMemberStakeReqParams) =>
  apiAxios.get<IVerifyClubMemberStakeResBody>(`/club-members/${params.id}/verify-stake`)
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))

// Rooms
const getRoom = (params: IGetRoomReqParams) => 
  apiAxios.get<IGetRoomResBody>(`/rooms/${params.id}`)
    .then(res => res.data)
    .catch((error: AxiosError) => handleError(error))

const getRooms = (query: IGetRoomsReqQuery) =>
  apiAxios.get<IGetRoomsResBody>('/rooms', {
    params: query
  })
    .then(res => res.data)
    .catch((error: AxiosError) => handleError(error))

const startRoomFarming = (params: IStartRoomFarmingReqParams) => 
  apiAxios.get<IStartRoomFarmingResBody>(`/rooms/${params.id}/start-room-farming`)
    .then(res => res.data)
    .catch((error: AxiosError) => handleError(error))

const stopRoomFarming = (params: IStopRoomFarmingReqParams) => 
  apiAxios.get<IStopRoomFarmingResBody>(`/rooms/${params.id}/stop-room-farming`)
    .then(res => res.data)
    .catch((error: AxiosError) => handleError(error))

const getRoomByChannelId = (params: IGetRoomByChannelIdReqParams) => 
  apiAxios.get<IGetRoomByChannelResBody>(`/rooms/by_channel_id/${params.chChannelId}`)
    .then(res => res.data.room)
    .catch((error: AxiosError) => handleError(error))

// RoomMembers
const getRoomMembers = (query: IGetRoomMembersReqQuery) =>
  apiAxios.get<IGetRoomMembersResBody>('/room-members', {
    params: query
  })
    .then(res => res.data.roomMembers)
    .catch((error: AxiosError) => handleError(error)) 

const getRoomMember = (params: IGetRoomMemberReqParams) => 
  apiAxios.get<IGetRoomMemberResBody>(`/room-members/${params.id}`)
    .then(res => res.data.roomMember)
    .catch((error: AxiosError) => handleError(error))

const verifyRoomMemberReward = (params: IVerifyRoomMemberRewardReqParams) => 
  apiAxios.get<IVerifyRoomMemberRewardResBody>(`/room-members/${params.id}/verify-reward`)
    .then(res => res.data)
    .catch((error: AxiosError) => handleError(error))

const domainSignIn = (body: { password: string }) =>
  apiAxios.post('/auth/domain-sigin', {
    password: body.password
  })
    .then(({ data }) => ({}))
    .catch((error: AxiosError) => handleError(error))
  
const signUp = (body: ISignUpBody) =>
  apiAxios.post<ISignUpRes>('/auth/signup', {
    email: body.email,
    password: body.password,
    // username: body.username
  })
    .then(({ data }) => data.account)
    .catch((error: AxiosError) => handleError(error))

const signOut = () =>
  apiAxios.get('/auth/signout')
    .then(({ data }) => data.account)
    .catch((error: AxiosError) => handleError(error))
    
const signInLocal = (body: ISignInLocalBody) =>
  apiAxios.post<ISignInLocalRes>('/auth/signin-local', {
    email: body.email,
    password: body.password
  })
    .then(({ data }) => data.account)
    .catch((error: AxiosError) => handleError(error))

const signInJwt = () =>
  apiAxios.post<ISignInJwtRes>('/auth/signin-jwt')
    .then(({ data }) => data.account)
    .catch((error: AxiosError) => handleError(error))

const connectBlockchainAccount = (body: IConnectBlockchainAccountBody) =>
  apiAxios.post<IConnectBlockchainAccountRes>('/account/blockchain/connect', body)
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))

const disconnectBlockchainAccount = (body: IDisconnectBlockchainAccountBody) =>
  apiAxios.post<IDisconnectBlockchainAccountRes>('/account/blockchain/disconnect', body)
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))

const connectClubhouseAccount = (body: IConnectClubhouseAccountBody) =>
  apiAxios.post<IConnectClubhouseAccountRes>('/account/clubhouse/connect', {
    username: body.username
  })
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))
    
const getAccount = (params: IGetAccountReqParams) =>
  apiAxios.get<IGetAccountResBody>('/account')
    .then(({ data }) => data.account)
    .catch((error: AxiosError) => handleError(error))

const verifyClubhouseAccount = (body: IVerifyClubhouseAccountBody) =>
  apiAxios.post<IVerifyClubhouseAccountRes>('/account/clubhouse/verify', {
    username: body.username
  })
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))

const getVerifyEmailLetter = () => 
  apiAxios.get(`/account/email/verify-letter`)
    .then(res => res.data)
    .catch((error: AxiosError) => handleError(error))

const connectClub = (body: IConnectClubBody) =>
  apiAxios.post<IConnectClubRes>('/clubs/connect', {
    clubUrl: body.clubUrl
  })
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))

const verifyClub = (body: IVerifyClubBody) =>
  apiAxios.post<IVerifyClubRes>(`/clubs/verify`, {
    clubId: body.clubId
  })
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))

const acceptBotInvitation = (body: IAcceptBotInvitationReqBody) =>
  apiAxios.post<IAcceptBotInvitationResBody>('/clubs/accept_bot_invitation', {
    clubId: body.clubId,
  })
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))
    
const verifyBotLeaderRole = (body: IVerifyBotLeaderRoleReqBody) =>
  apiAxios.post<IVerifyBotLeaderRoleResBody>('/clubs/verify_bot_leader_role', {
    clubId: body.clubId,
  })
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))

const verifyContractConnection = (body: IVerifyContractConnectionReqBody) =>
  apiAxios.post<IVerifyContractConnectionResBody>('/clubs/verify_contract_connection', {
    clubId: body.clubId,
  })
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))

const getAddClubMessageSignature= (body: IGetAddClubMessageSignatureReqBody) =>
  apiAxios.post<IGetAddClubMessageSignatureResBody>('/blockchain/messages/add-club/signature', body)
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))
    
const getSubscribeOnClubMessageSignature = (body: ISubscribeOnClubMessageSignatureReqBody) =>
  apiAxios.post<ISubscribeOnClubMessageSignatureResBody>('/blockchain/messages/subscribe-on-club/signature', body)
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))
  
const getAddRoomMessageSignature= (body: IGetAddRoomMessageSignatureReqBody) =>
  apiAxios.post<IGetAddRoomMessageSignatureResBody>('/blockchain/messages/add-room/signature', body)
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))

const getGetRoomMemberRewardMessageSignature= (body: IGetGetRoomMemberRewardMessageSignatureReqBody) =>
  apiAxios.post<IGetGetRoomMemberRewardMessageSignatureResBody>('/blockchain/messages/get-room-member-reward/signature', body)
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleError(error))

export default {
  getClub,
  getClubs,
  getClubInvite,
  // changeClubStatus,
  getClubBySlug,
  getRoomByChannelId,
  connectClub,
  verifyClub,
  acceptBotInvitation,
  verifyBotLeaderRole,
  verifyContractConnection,

  // ClubMembers
  getClubMember,
  getClubMembers,
  becomeClubMember,
  verifyClubMemberMembership,
  verifyClubMemberStake,

  // Rooms
  getRoom,
  getRooms,
  startRoomFarming,
  stopRoomFarming,

  // RoomMembers
  getRoomMembers,
  getRoomMember,
  verifyRoomMemberReward,

  // Auth
  domainSignIn,
  signUp,
  signInLocal,
  signInJwt,
  signOut,

  // Account
  getAccount,
  verifyClubhouseAccount,
  connectBlockchainAccount,
  disconnectBlockchainAccount,
  connectClubhouseAccount,
  getVerifyEmailLetter,

  getAddClubMessageSignature,
  getAddRoomMessageSignature,
  getGetRoomMemberRewardMessageSignature,
  getSubscribeOnClubMessageSignature,
}

export {
  ApiAppError,
  isApiAppError
}
