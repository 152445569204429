import { notification } from 'components/antd'

interface INotificationParams {
  message?: string,
  description?: string
}

notification.config({
  placement: 'bottomRight',
  duration: 5
})

const showErrorNotification = ({ message, description }: INotificationParams) =>
  notification.open({
    message,
    description,
    className: 'ant-notification-notice__error'
  })

const showSuccessNotification = ({ message, description }: INotificationParams) =>
  notification.open({
    message,
    description,
    className: 'ant-notification-notice__success'
  })

export default {
  showErrorNotification,
  showSuccessNotification
}
