import { getEnvVar } from '@decibel-coin/decibel-sdk/lib/utils'

const DBC_TOKEN_CONTRACT_ADDR = getEnvVar('APP_DBC_TOKEN_CONTRACT_ADDR')
const MASTER_CONTRACT_ADDR = getEnvVar('APP_MASTER_CONTRACT_ADDR')
const MULTICALL_CONTRACT_ADDR = getEnvVar('APP_MULTICALL_CONTRACT_ADDR')

export default {
  decibelCoin: {
    56: DBC_TOKEN_CONTRACT_ADDR,
    97: DBC_TOKEN_CONTRACT_ADDR,
    1337: DBC_TOKEN_CONTRACT_ADDR,
  },
  master: {
    56: MASTER_CONTRACT_ADDR,
    97: MASTER_CONTRACT_ADDR,
    1337: MASTER_CONTRACT_ADDR,
  },
  multiCall: {
    56: MULTICALL_CONTRACT_ADDR,
    97: MULTICALL_CONTRACT_ADDR,
    1337: MULTICALL_CONTRACT_ADDR,
  },
}
