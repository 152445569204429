import React, { FC, useMemo } from 'react'
import { Slider } from 'components/antd'
import cn from 'classnames'
import { SliderSingleProps } from 'antd/lib/slider'

import styles from './styles.module.scss'

type Theme = 'white' | 'blue' | 'blue-light' | 'green' | 'red' | 'gray-light'
type Size = 'xs' | 'sm' | 'md' | 'lg'

interface IProps extends Omit<SliderSingleProps, 'size'> {
  error?: string
  label?: string
  className?: string
  isDisabled?: boolean
  size?: Size
  theme?: Theme
}

const SliderField: FC<IProps> = ({
  label,
  value,
  min,
  max,
  onChange,
  error,
  className,
  isDisabled,
  size = 'xs',
  theme = 'white',
  defaultValue,
  tooltipVisible,
  step
}) => {
  const rootCN = useMemo(() => {
    return cn(
      styles.root,
      className,
      { [styles.root__isDisabled]: isDisabled },
      { [styles[`root__style_${theme}`]]: true },
      { [styles[`root__size_${size}`]]: true }
    )
  }, [className, isDisabled, theme, size])

  return (
    <div className={rootCN}>
      {label && (
        <div className={styles.section}>
          <div className={styles.label}>
            {label}
          </div>
        </div>
      )}

      <div className={styles.section}>
        <div className={cn(
          styles.control,
          { [styles.control__hasError]: !!error }
        )}>
          <Slider
            value={value}
            defaultValue={defaultValue}
            min={min}
            max={max}
            tooltipVisible={tooltipVisible}
            step={step}
            tipFormatter={percent => `${percent}%`}
            tooltipPlacement='bottom'
            onChange={onChange}
          />

          {!!error && (
            <div className={styles.error}>
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
  
export default SliderField