import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import cn from 'classnames'
import Media from 'react-media'

import { TRoomMemberPopulated, TRoomPopulated, RoomMemberRewardState, RoomRewardState } from '@decibel-coin/decibel-sdk/lib/types/entities'

import useToast from 'hooks/useToast'
import { useMasterContract }  from 'hooks/useContract'
import useErrorHandler from 'hooks/useErrorHandler'

import { useAccount } from 'state/account/hooks'

import { Button, ConnectMetamaskBtn, Avatar } from 'components'
import { ToastDescriptionWithTx2 } from 'components/Toast'
import { formatTime, formatBasePoints, formatDBCToken, formatPercent, breakpoints } from 'utils'
import { ApiService } from 'services'

import styles from './styles.module.scss'

interface IProps {
  room: TRoomPopulated
  roomMember: TRoomMemberPopulated
  onCollectRoomReward?: () => Promise<void>
}

const RoomMemberDetails: React.FC<IProps> = ({
  room,
  roomMember,
  onCollectRoomReward
} ) => {
  const chAccount = roomMember.account.chAccount

  const { toastSuccess } = useToast()
  const { handleError } = useErrorHandler()
  const masterContract = useMasterContract()
  const { account: address } = useWeb3React()
  const { isAuthorized, isSignInPending, account, signInLocal, signOut } = useAccount()

  const [isCollectRewardActionPending, setIsCollectRewardActionPending] = useState(false)

  const handleCollectReward = async () => {
    if (room) {
      try {
        setIsCollectRewardActionPending(true)

        const { signature, nonce, roomMemberMetricsData } = await ApiService.getGetRoomMemberRewardMessageSignature({
          roomId: room.chRoomId,
          clubId: room.club.chClubId
        })

        const getRoomMemberRewardCall = await masterContract.getRoomMemberReward(
          nonce,
          signature,
          room.chRoomId,
          room.club.chClubId,
          roomMemberMetricsData,
          { from: address }
        )

        await getRoomMemberRewardCall.wait()

        await ApiService.verifyRoomMemberReward({
          id: roomMember.id
        })

        if (onCollectRoomReward) {
          await onCollectRoomReward()
        }

        toastSuccess(
          'Reward claimed!',
          <ToastDescriptionWithTx2 txHash={getRoomMemberRewardCall.hash}>
            Successful room reward claim
          </ToastDescriptionWithTx2>,
        )

        setIsCollectRewardActionPending(false)
      } catch (error) {
        console.error(error)

        setIsCollectRewardActionPending(false)
        
        handleError(error)
      }
    }
  }

  if (account && address && account.blockchainAddress && account.blockchainAddress !== address) {
    return <Redirect exact to="/connect-blockchain-account" />
  }

  return (
    <Media queries={{ xs: { maxWidth: breakpoints.xs } }}>
      {matches => (
        <div className={cn(styles.root)}>
          <div className={styles.header}>
            <div className={styles.header_left}>
              <div className={styles.avatar}>
                <Avatar
                  image={roomMember.account.chAccount.photoUrl}
                  size={matches ? 'xs' : 'sm'}
                />
              </div>

              <div className={styles.names}>
                <div className={styles.names_name}>
                  {chAccount.name ? chAccount.username : chAccount.name}
                </div>

                {/* <div className={styles.names_username}>
                  @{chAccount.username}
                </div> */}
              </div>
            </div>
          </div>

          <div className={styles.content}>
            <div className={cn(styles.section, styles.section__reward, styles.reward)}>
              <div className={styles.section_title}>
                Reward data
              </div>

              <div className={styles.section_content}>
                <div className={styles.details}>
                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Pending reward
                    </div>

                    <div className={cn(styles.detail_value, styles.detail_value__highlighted)}>
                      {formatDBCToken(roomMember.pendingReward)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Claimed reward
                    </div>

                    <div className={cn(styles.detail_value, styles.detail_value__highlighted)}>
                      {formatDBCToken(roomMember.reward)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      DBC reward share
                    </div>

                    <div className={styles.detail_value}>
                      {roomMember.rewardState === RoomMemberRewardState.pending
                        ? formatPercent(roomMember.pendingReward, room.pendingReward)
                        : formatPercent(roomMember.reward, room.reward)
                      }
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Staking amount
                    </div>

                    <div className={cn(styles.detail_value, styles.detail_value__highlighted)}>
                      {formatDBCToken(roomMember.roomMemberStakingAmount)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Staking share
                    </div>

                    <div className={styles.detail_value}>
                      {formatPercent(roomMember.roomMemberStakingAmount, room.roomMembersStakingAmount)}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.section_footer}>
                <div className={styles.reward_actions}>
                  {(address)
                    ? (
                      <>
                        <div className={styles.reward_action}>
                          <Button
                            type='button'
                            theme='green'
                            isDisabled={
                              room.rewardState === RoomRewardState.pending ||
                              roomMember.rewardState === RoomMemberRewardState.paid ||
                              isCollectRewardActionPending
                            }
                            text={isCollectRewardActionPending ? 'Claim pending reword...' : 'Claim pending reword'}
                            size={matches.xs ? 'xs' : 'sm'}
                            onClick={handleCollectReward}
                          />
                        </div>

                        {/* 
                        <div className={styles.reward_action}>
                          <Button
                            type='button'
                            theme='gray-light'
                            isDisabled={true}
                            text="Restake reword to club's staking"
                            onClick={() => {}}
                          />
                        </div> */}
                      </>
                    )
                    : (
                      <div className={styles.reward_action}>
                        <ConnectMetamaskBtn/>
                      </div>
                  )}
                </div>
              </div>
            </div>

            <div className={cn(styles.section, styles.section__metrics, styles.metrics)}>
              <div className={styles.section_title}>
                Room member metrics
              </div>

              <div className={styles.section_content}>
                <div className={styles.details}>
                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Moderator time
                    </div>

                    <div className={styles.detail_value}>
                      {formatTime(roomMember.metrics.moderatorTime)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Speaker time
                    </div>

                    <div className={styles.detail_value}>
                      {formatTime(roomMember.metrics.speakerTime)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Listener time
                    </div>

                    <div className={styles.detail_value}>
                      {formatTime(roomMember.metrics.listenerTime)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Stickiness
                    </div>

                    <div className={styles.detail_value}>
                      {formatBasePoints(roomMember.metrics.stickiness)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Media>
  )
}

export default RoomMemberDetails
