import React, { lazy, useEffect } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import 'antd/dist/antd.min.css'
import './style/index.scss'

import { useAppDispatch } from 'state'
import { signInJwt } from 'state/account'

import Layout from 'components/Layout'
import AuthRoute from 'components/AuthRoute'

import useEagerConnect from 'hooks/useEagerConnect'

import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import history from './routerHistory'

const SignIn = lazy(() => import('./views/SignIn'))
const SignUp = lazy(() => import('./views/SignUp'))
const ConnectAccount = lazy(() => import('./views/ConnectAccount'))
const ConnectBlockchainAccount = lazy(() => import('./views/ConnectBlockchainAccount'))
const VerifyEmail = lazy(() => import('./views/VerifyEmail'))
const Account = lazy(() => import('./views/Account'))
const Club = lazy(() => import('./views/Club'))
const Clubs = lazy(() => import('./views/Clubs'))
const Room = lazy(() => import('./views/Room'))
const Rooms = lazy(() => import('./views/Rooms'))
const ConnectClub = lazy(() => import('./views/ConnectClub'))
const NotFound = lazy(() => import('./views/NotFound'))

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(signInJwt())
  }, [])

  useEagerConnect()
  
  return (
    <Router history={history}>
      <Layout>
        <React.Suspense fallback={null}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/clubs" />
            </Route>

            <Route exact path="/clubs" component={Clubs} />
            <Route path="/clubs/:clubId" component={Club} />

            <Route exact path="/rooms" component={Rooms} />
            <Route path="/rooms/:roomId" component={Room} />

            <Route path="/signin" exact>
              <SignIn />
            </Route>

            <Route path="/signup" exact>
              <SignUp />
            </Route>

            <Route path="/connect-account" exact>
              <ConnectAccount />
            </Route>

            <Route path="/connect-blockchain-account" exact>
              <ConnectBlockchainAccount />
            </Route>

            <Route path="/verify-email" exact>
              <VerifyEmail />
            </Route>

            <AuthRoute exact path="/connect-club">
              <ConnectClub />
            </AuthRoute>

            <AuthRoute exact path="/account">
              <Account />
            </AuthRoute>

            <Route component={NotFound} />
          </Switch>
        </React.Suspense>
      </Layout>
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)
