import React, { useMemo, useEffect, useState } from 'react'

import { IClub } from '@decibel-coin/decibel-sdk/lib/types/entities'
import { TListField, IGetClubsReqQuery, IGetClubsResBody } from '@decibel-coin/decibel-sdk/lib/types/api'

import useErrorHandler from 'hooks/useErrorHandler'
import { useList, TMakeListReq, THandleListRes } from 'hooks/useList'

import { ApiService } from 'services'

import { ClubPreview } from 'components'
import { FieldWrapper } from 'components/Filters/fields'

import styles from './styles.module.scss'

interface IProps {
  getClubsQuery?: IGetClubsReqQuery
}

const ClubsList: React.FC<IProps> = ({
  getClubsQuery
}) => {
  const { handleError } = useErrorHandler()

  const [clubs, setClubs] = useState<IClub[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const makeRoomsListReq = async (query: IGetClubsReqQuery) => {
    return ApiService.getClubs({
      page: 1,
      sorting: query.sorting,
      staked_by_account: query.staked_by_account
    })
  }

  const handleRoomsListRes = (resBody: IGetClubsResBody) => {
    setClubs(resBody.items)
  }

  const { handleUpdateField, isLoading: isGetRoomsLoading, load: loadRooms, fields } = useList(
    makeRoomsListReq as TMakeListReq,
    handleRoomsListRes as THandleListRes
  )

  const onUpdateField = (field: TListField) => (payload) => {
    handleUpdateField(field, payload)
  }

  const onCloseField = () => {    
    // console.log('onCloseField')
  }


  if (clubs.length === 0 && isLoading) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.filter}>
        {fields.map((field) =>
          <div className={styles.filter_field} key={field.name}>
            <FieldWrapper
              field={field}
              mode='inline'
              onUpdateField={onUpdateField(field)}
              onCloseField ={onCloseField}
            />
          </div>
        )}
      </div>

      <div className={styles.list}>
        {clubs.map(club => (
          <ClubPreview
            key={club.id}
            className={styles.list_item}
            club={club}
          />
        ))}
      </div>
    </div>
  )
}

export default ClubsList
