import React from 'react'

import {
  TListField,
  // IRangeField,
  IListField,
  IListSwitchField
} from '@decibel-coin/decibel-sdk/lib/types/api'

// import * as RangeField from './RangeField'
import * as ListField from './ListField'
import * as SwitchField from './SwitchField'

// const RangeFieldComponent = RangeField.default
const ListFieldComponent = ListField.default
const SwitchFieldComponent = SwitchField.default

function hasFieldValue(field: TListField): boolean {
  switch (field.type) {
    // case 'Range': {
    //   return RangeField.hasValueFn(field as IRangeField)
    // }
    case 'List': {
      return ListField.hasValueFn(field as IListField)
    }
    case 'Switch': {
      return SwitchField.hasValueFn(field as IListSwitchField)
    }
  }
}


function serializeField(field: TListField) {
  switch (field.type) {
    // case 'Range': {
    //   return RangeField.serializeFn(field as IRangeField)
    // }
    case 'List': {
      return ListField.serializeFn(field as IListField)
    }
    case 'Switch': {
      return SwitchField.serializeFn(field as IListSwitchField)
    }
  }
}

function updateField(field: TListField, payload: any) {
  switch (field.type) {
    // case 'Range': {
    //   return RangeField.updateFn(field as IRangeField, payload)
    // }
    case 'List': {
      return ListField.updateFn(field as IListField, payload)
    }
    case 'Switch': {
      return SwitchField.updateFn(field as IListSwitchField, payload)
    }
  }
}

function resetField(field: TListField) {
  switch (field.type) {
    // case 'Range': {
    //   return RangeField.resetFn(field as IRangeField)
    // }
    case 'List': {
      return ListField.resetFn(field as IListField)
    }
    case 'Switch': {
      return SwitchField.resetFn(field as IListSwitchField)
    }
  }
}

interface IProps {
  field: TListField
  onUpdateField: (payload: any) => void
  onCloseField?: () => void,
  mode: 'modal' | 'inline'
}

function FieldWrapper(props: IProps) {
  switch (props.field.type) {
    // case 'Range': {
    //   return (
    //     <RangeFieldComponent
    //       {...props}
    //       field={props.field as IRangeField}
    //     />
    //   )
    // }
    case 'List': {
      return (
        <ListFieldComponent
          {...props}
          field={props.field as IListField}
        />
      )
    }
    case 'Switch': {
      return (
        <SwitchFieldComponent
          {...props}
          field={props.field as IListSwitchField}
        />
      )
    }
  }
}

export {
  hasFieldValue,
  serializeField,
  resetField,
  updateField,
  FieldWrapper
}
