import React, { FC, ChangeEvent } from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

interface IProps {
  className?: string
  text?: string
  to: string
  hasIcon?: boolean
  target?: '_blank'
}

const ExternalLink: FC<IProps> = ({
  className,
  text,
  to,
  hasIcon = true,
  target = '_blank'
}) => (
  <a
    target={target}
    href={to}
    className={cn(styles.root, className)}
  >
    {text && (
      <div className={styles.root_text}>
        {text}
      </div>
    )}

    {hasIcon && (
      <div className={styles.root_icon}/>
    )}
  </a>
)
  
export default ExternalLink