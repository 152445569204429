import React, { FC, MouseEventHandler, HTMLFactory } from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

interface IProps {
  children: React.ReactNode
  className?: string
  onClick?: () => void
}

const Box: FC<IProps> = ({
  children,
  className,
  onClick
}) => (
  <div
    className={cn(styles.root, className)}
    onClick={onClick}
  >
    {children}
  </div>
)
  
export default Box