import React, { ReactNode, useMemo }  from 'react'
import cn from 'classnames'
import BigNumber from 'bignumber.js'
import Media from 'react-media'

import { IClub } from '@decibel-coin/decibel-sdk/lib/types/entities'

import { useAppDispatch } from 'state'
import { Link, ExternalLink, Avatar } from 'components'
import { formatDBCToken, breakpoints } from 'utils'

import styles from './styles.module.scss'

interface IProps {
  club: IClub
  actions?: ReactNode
}

const ClubDetails: React.FC<IProps> = ({
  club,
  actions
} ) => {
  const dispatch = useAppDispatch()
  // const avRewardPerMember = useMemo(() => {
  //   return new BigNumber(room.reward).div(roomMembersCount)
  // }, [room.reward, roomMembersCount])

  return (
    <Media queries={{ xs: { maxWidth: breakpoints.xs } }}>
      {matches => (
        <div className={cn(styles.root)}>
          <div className={styles.header}>
            <div className={styles.header_left}>
              <div className={styles.avatar}>
                <Avatar
                  image={club.chClub.photoUrl}
                  size={matches ? 'xs' : 'sm'}
                />
              </div>

              <div>
                <Link
                  className={styles.clubName}
                  text={club.chClub.name}
                  hasIcon={!matches.xs}
                  to={`/clubs/${club.id}`}
                />

                <div className={styles.chLink}>
                  <ExternalLink
                    text='view in clubhouse'
                    target='_blank'
                    hasIcon={matches.xs ? false : true}
                    to={club.chClub.url}
                  />
                </div>
              </div>
            </div>

            <div className={styles.header_right}>
              {actions && (
                <div className={styles.actions}>
                  {actions}
                </div>
              )}
            </div>
          </div>

          <div className={styles.content}>
            <div className={cn(styles.section, styles.section__reward)}>
              <div className={styles.section_title}>
                Reward data
              </div>

              <div className={styles.section_content}>
                <div className={styles.details}>
                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Total earned
                    </div>

                    <div className={cn(styles.detail_value, styles.detail_value__highlighted)}>
                      {formatDBCToken(new BigNumber(club.metrics.totalReward))}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Average DBC reward per room
                    </div>

                    <div className={styles.detail_value}>
                      {formatDBCToken(new BigNumber(club.metrics.averageRewardPerRoom))}
                    </div>
                  </div>

                  {/* <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Average DBC reward per member
                    </div>

                    <div className={styles.detail_value}>
                      {formatDBCToken(new BigNumber(0))}
                    </div>
                  </div> */}

                  {/* <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Average DBC reward per minute
                    </div>

                    <div className={styles.detail_value}>
                      {formatDBCToken(new BigNumber(0))}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className={cn(styles.section, styles.section__staking)}>
              <div className={styles.section_title}>
                Staking data
              </div>

              <div className={styles.section_content}>
                <div className={styles.details}>
                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Total staked
                    </div>

                    <div className={cn(styles.detail_value, styles.detail_value__highlighted)}>
                      {formatDBCToken(new BigNumber(club.metrics.totalStaking))}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Average DBC staking per member
                    </div>

                    <div className={styles.detail_value}>
                      {formatDBCToken(new BigNumber(club.metrics.averageStakingPerMember))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={cn(styles.section, styles.section__info)}>
              <div className={styles.section_title}>
                Club data
              </div>

              <div className={styles.section_content}>
                <div className={styles.details}>
                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Members count:
                    </div>

                    <div className={styles.detail_value}>
                      {club.metrics.totalMembersCount}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Rooms count:
                    </div>

                    <div className={styles.detail_value}>
                      {club.metrics.totalRoomsCount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Media>
  )
}

export default ClubDetails
