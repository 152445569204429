import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { IPrivateAccount } from '@decibel-coin/decibel-sdk/lib/types/entities'
import { ApiService } from 'services'

import { AccountState, AppThunk } from 'state/types'

const initialState: AccountState = {
  account: undefined,
  isSignInPending: true,
  isSignUpPending: false,
  isSignOutPending: false,
  isAuthorized: false
}

// Thunks
export const signInLocal = (email: string, password: string): AppThunk => async (dispatch, getState) => {
  dispatch(setSignInPendingStatus(true))
  
  return ApiService.signInLocal({
    email,
    password
  })
    .then(account => {
      dispatch(setAccount(account))
      
      dispatch(setSignInPendingStatus(false))
    })
    .catch((error) => {
      dispatch(setSignInPendingStatus(false))

      throw(error)
    })
  }

export const signInJwt = (): AppThunk => async (dispatch, getState) => {
  dispatch(setSignInPendingStatus(true))

  return ApiService.signInJwt()
    .then(account => {
      dispatch(setAccount(account))

      dispatch(setSignInPendingStatus(false))
    })
    .catch((error) => {
      dispatch(setSignInPendingStatus(false))
      console.log('signInJwt failed')

      // throw(error)
    })
  }

export const getAccount = (): AppThunk => async (dispatch, getState) => {
  return ApiService.getAccount({})
    .then(account => {
      dispatch(setAccount(account))
    })
    .catch((error) => {
      throw(error)
    })
  }

export const signOut = (): AppThunk => async (dispatch, getState) => {
  // dispatch(setSignInPendingStatus(true))

  return ApiService.signOut()
    .then(() => {
      // dispatch(setSignInPendingStatus(false))

      dispatch(clearAccount())
    })
    .catch((error) => {
      // dispatch(setSignInPendingStatus(false))

      throw(error)
    })
  }

export const signUp = (email: string, password: string): AppThunk => async (dispatch, getState) => {
  dispatch(setSignUpPendingStatus(true))

  return ApiService.signUp({
    email,
    password
  })
    .then(account => {
      dispatch(setSignUpPendingStatus(false))

      dispatch(setAccount(account as IPrivateAccount))
    })
    .catch((error) => {
      dispatch(setSignUpPendingStatus(false))

      throw(error)
    })
  }

export const AccountSlice = createSlice({
  name: 'Account',
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<IPrivateAccount>) => {
      state.account = action.payload
      state.isAuthorized = true
    },
    clearAccount: () => ({
      ...initialState
    }),
    setSignInPendingStatus: (state, action: PayloadAction<boolean>) => {
      state.isSignInPending = action.payload
    },
    setSignUpPendingStatus: (state, action: PayloadAction<boolean>) => {
      state.isSignUpPending = action.payload
    },
  },
  extraReducers: {
    
  }
})

// Actions
export const { setAccount, clearAccount, setSignInPendingStatus, setSignUpPendingStatus } = AccountSlice.actions

export default AccountSlice.reducer
