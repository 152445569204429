import { useMemo, useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { Token, TokenAmount } from '@decibel-coin/decibel-contracts/lib/entities'
import { Bep20 } from '@decibel-coin/decibel-contracts/lib/contracts/types/Bep20'

import { useBlock } from 'state/block/hooks'
import { useSingleCallResult } from 'state/multicall/hooks'

import { useTokenContract } from 'hooks/useContract'
import useRefresh from 'hooks/useRefresh'

import { getBep20Contract } from 'utils/contractHelpers'
import { BIG_ZERO } from 'utils/bigNumber'

function useTokenAllowance(token?: Token, owner?: string, spender?: string): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result

  return useMemo(
    () => (token && allowance ? new TokenAmount(token, allowance.toString()) : undefined),
    [token, allowance],
  )
}

export default useTokenAllowance

type UseTokenBalanceState = {
  balance: BigNumber
  fetchStatus: FetchStatus
}

export enum FetchStatus {
  NOT_FETCHED = 'not-fetched',
  SUCCESS = 'success',
  FAILED = 'failed',
}

const useTokenAllowanceV2 = (token?: Token, owner?: string, spender?: string) => {
  const [allowanceState, setAllowanceState] = useState<UseTokenBalanceState>({
    balance: BIG_ZERO,
    fetchStatus: FetchStatus.NOT_FETCHED,
  })

  const { account } = useWeb3React()
  const { slowRefresh } = useRefresh()

  const contract = getBep20Contract(token.address) as any as Bep20

  useEffect(() => {
    const fetchBalance = async () => {
      
      try {
        const allowance = await contract.allowance(owner, spender)

        setAllowanceState({
          balance: new BigNumber(allowance.toString()),
          fetchStatus: FetchStatus.SUCCESS
        })
      } catch (e) {
        console.error(e)
        setAllowanceState((prev) => ({
          ...prev,
          fetchStatus: FetchStatus.FAILED,
        }))
      }
    }

    if (account) {
      fetchBalance()
    }
  }, [contract, account, token, owner, spender, slowRefresh])

  return allowanceState
}

const useTokenAllowanceV3 = (token?: Token, owner?: string, spender?: string) => {
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  // const { currentBlock } = useBlock()

  const [allowance, setAllowance] = useState<BigNumber | undefined>(undefined)

  useEffect(() => {
    const contract = getBep20Contract(token.address) as any as Bep20

    const fetchAllowance = async () => {
      try {
        const allowanceValue = await contract.allowance(owner, spender)

        setAllowance(new BigNumber(allowanceValue.toString()))
      } catch (e) {
        console.log('unknown', 'error', e)
        setAllowance((prev) => prev)
      }
    }

    // if (account) {
      fetchAllowance()
    // }
  }, [account, token, owner, spender, fastRefresh])

  return useMemo(
    () => (token && allowance ? new TokenAmount(token, allowance.toString()) : undefined),
    [token, allowance],
  )
}

export {
  // useTokenAllowance,
  useTokenAllowanceV2,
  useTokenAllowanceV3
}
