import React, { FC, ChangeEvent, useMemo } from 'react'
import cn from 'classnames'

import { Switch } from 'components/antd'

import styles from './styles.module.scss'

type Theme = 'white' | 'blue' | 'blue-light' | 'green' | 'red' | 'gray-light'
type Size = 'xs' | 'sm' | 'md' | 'lg'

interface IProps {
  label?: string
  prefix?: string
  postfix?: string
  error?: string
  value: boolean
  className?: string
  isDisabled?: boolean
  size?: Size
  theme?: Theme
  onChange: (value: boolean) => void
}

const SelectField: FC<IProps> = ({
  label,
  value,
  className,
  isDisabled,
  theme = 'white',
  size = 'sm',
  onChange,
  prefix,
  postfix,
  error
}) => {
  const rootCN = useMemo(() => {
    return cn(
      styles.root,
      className,
      { [styles.root__isDisabled]: isDisabled },
      { [styles[`root__style_${theme}`]]: true },
      { [styles[`root__size_${size}`]]: true }
    )
  }, [className, isDisabled, theme, size])

  return (
    <div className={rootCN} id={rootCN}>
      {label && (
        <div className={styles.section}>
          <div className={styles.label}>
            {label}
          </div>
        </div>
      )}

      <div className={styles.section}>
        {!!prefix && 
          <div className={styles.prefix}>
            {prefix}
          </div>
        }

        <div className={cn(styles.control, {
          [styles.control__hasError]: !!error
        })}>
          <Switch
            checked={value}
            className={styles.switch}
            onChange={onChange}
          />
        </div>

        {!!postfix && 
          <div className={styles.postfix}>
            {postfix}
          </div>
        }
      </div>
    </div>
  )
}
  
export default SelectField