import React, { Component, useMemo, useState, useCallback, useEffect }  from 'react'
import { useWeb3React } from '@web3-react/core'
import cn from 'classnames'
import BigNumber from 'bignumber.js'
import Media from 'react-media'

import { IClub, IClubMember } from '@decibel-coin/decibel-sdk/lib/types/entities'
import { ether, clubMembershipPrice } from '@decibel-coin/decibel-contracts/lib/contract-utils'

import useToast from 'hooks/useToast'
import useErrorHandler from 'hooks/useErrorHandler'
import { useMasterContract }  from 'hooks/useContract'
import { useGetDBCBalance } from 'hooks/useTokenBalance'
import { useClubStakingPoolBalance } from 'hooks/useClubStakingPoolBalance'

import { NumberField, SliderField, Button } from 'components'
import { ToastDescriptionWithTx2 } from 'components/Toast'
import { formatDBCToken, breakpoints } from 'utils'
import { ApiService } from 'services'

import { Modal } from 'components/antd'

import styles from './styles.module.scss'

type Mode = 'withdraw' | 'deposit'

interface IProps {
  club: IClub
  clubMember: IClubMember
  isOpened: boolean
  mode?: Mode
  onClose: () => void
  onLoadData: () => void
}

const StakeModal: React.FC<IProps> = ({
  club,
  clubMember,
  isOpened,
  mode = 'deposit',
  onClose,
  onLoadData
}) => {
  const [stakeAmount, setStakeAmount] = useState('0')
  const [stakePercent, setStakePercent] = useState(0)
  const [isStakeActionPending, setIsStakeActionPending] = useState(false)

  const { toastSuccess } = useToast()
  const { handleError } = useErrorHandler()
  const { account: address } = useWeb3React()
  const masterContract = useMasterContract()

  const { balance: dbcBalance } = useGetDBCBalance()
  const { balance: stakingPoolBalance } = useClubStakingPoolBalance(club.chClubId)

  // const userNotEnoughToken = isRemovingStake
  //   ? userData.stakedBalance.lt(fullDecimalStakeAmount)
  //   : userData.stakingTokenBalance.lt(fullDecimalStakeAmount)

  // useEffect(() => {
  //     if (stakingLimit.gt(0) && !isRemovingStake) {
  //       setHasReachedStakedLimit(fullDecimalStakeAmount.plus(userData.stakedBalance).gt(stakingLimit))
  //     }
  // }, [
  //     stakeAmount,
  //     stakingLimit,
  //     userData,
  //     stakingToken,
  //     isRemovingStake,
  //     setHasReachedStakedLimit,
  //     fullDecimalStakeAmount,
  //   ])

  const stakingLimit = useMemo(() => {
    return mode === 'withdraw'
      ? stakingPoolBalance.minus(clubMembershipPrice)
      : dbcBalance
  }, [dbcBalance, stakingPoolBalance, mode])

  const sliderProps = useMemo(() => {
    return {
      min: 0,
      max: 100,
      step: 1
    }
  }, [])

  const inputProps = useMemo(() => {
    return {
      value: (new BigNumber(stakeAmount)).dividedBy(ether).toString(),
      min: '0',
      max: stakingLimit.dividedBy(ether).toString(),
      step: (new BigNumber(1)).dividedBy(ether).toString()
    }
  }, [stakingLimit, stakeAmount])

  const handleChangeStakeAmount = useCallback((inputValue: string | null) => {
    const inputValueBN = new BigNumber(inputValue || 0).multipliedBy(ether)
    let value: BigNumber = new BigNumber(0)

    if (inputValueBN.gt(stakingLimit)) {
      value = new BigNumber(stakingLimit)
    } else if (inputValueBN.lt(0)) {
      value = new BigNumber(0)
    } else {
      value = inputValueBN
    }

    if (value) {
      const percentage = Math.floor(value.dividedBy(stakingLimit).multipliedBy(100).toNumber())
      setStakePercent(Math.min(percentage, 100))
    } else {
      setStakePercent(0)
    }
    setStakeAmount(value.toString())
  }, [stakingLimit])

  const handleChangeStakePercent = useCallback((sliderPercent: number | null) => {
    if (sliderPercent > 0) {
      const percentageOfStakingMax = stakingLimit.dividedBy(100).multipliedBy(sliderPercent)
      const amountToStake = percentageOfStakingMax.toString()
      setStakeAmount(amountToStake)
    } else {
      setStakeAmount((new BigNumber(0)).toString())
    }
    setStakePercent(sliderPercent)
  }, [stakingLimit])

  const deposit = useCallback(async () => {
    const depositCall = await masterContract.deposit(
      club.chClubId,
      stakeAmount,
      { from: address },
    )

    await depositCall.wait()

    await ApiService.verifyClubMemberStake({
      id: clubMember.id
    })

    return depositCall.hash
  }, [stakeAmount, address, club, clubMember, masterContract])

  const withdraw = useCallback(async () => {
    const withdrawCall = await masterContract.withdraw(
      club.chClubId,
      stakeAmount,
      { from: address },
    )

    await withdrawCall.wait()

    await ApiService.verifyClubMemberStake({
      id: clubMember.id
    })

    return withdrawCall.hash
  }, [stakeAmount, address, club, clubMember, masterContract])


  const handleConfirm = useCallback(async () => {
    if (!club || !address) return 

    try {
      setIsStakeActionPending(true)

      if (mode === 'deposit') {
        await deposit()
          .then((txHash) => {
            onClose()
            
            toastSuccess(
              'Staked!',
              <ToastDescriptionWithTx2 txHash={txHash}>
                {`Successful stake into ${club.chClub.name}'s staking pool`}
              </ToastDescriptionWithTx2>,
            )
          })

      } else {
        await withdraw()
          .then((txHash) => {
            onClose()

            toastSuccess(
              'Staked!',
              <ToastDescriptionWithTx2 txHash={txHash}>
                {`Successfully unstaked from ${club.chClub.name}'s staking pool`}
              </ToastDescriptionWithTx2>,
            )
          })
      }

      onLoadData()

      setIsStakeActionPending(false)
    } catch (error) {
      handleError(error)

      setIsStakeActionPending(false)
    }
  }, [address, club, mode, deposit, withdraw, handleError, toastSuccess, onLoadData, onClose])

  const isStakeActionBtnDisabled = useMemo(() => {
    return (
      !address || 
      isStakeActionPending ||
      (new BigNumber(stakeAmount)).lte(0)
    )
  }, [address, isStakeActionPending, stakeAmount])

  return (
    <Modal
      className={styles.modal}
      wrapClassName={styles.modalWrap}
      closable={false}
      centered
      visible={isOpened}
      footer={null}
      onCancel={() => onClose()}
    >
      <Media queries={{ xs: { maxWidth: breakpoints.xs } }}>
        {matches => (
          <div className={cn(styles.root)}>
            <div className={styles.header}>
              {mode === 'deposit'
                ? "Stake into Club's staking pool"
                : "Unstake from Club's staking pool"
              }
            </div>

            <div className={styles.content}>
              <div className={styles.section}>
                <div className={styles.details}>
                 <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Balance:
                    </div>

                    <div className={styles.detail_value}>
                      {formatDBCToken(dbcBalance)}
                    </div>
                  </div>

                  <div className={styles.detail}>
                    <div className={styles.detail_name}>
                      Staked:
                    </div>

                    <div className={styles.detail_value}>
                      {formatDBCToken(stakingPoolBalance)}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.section}>
                <NumberField
                  onChange={handleChangeStakeAmount}
                  value={inputProps.value}
                  min={inputProps.min}
                  max={inputProps.max}
                  step={inputProps.step}
                  stringMode
                  size={matches.xs ? 'sm' : 'md'}
                  theme='gray-light'
                  label={mode === 'deposit'
                    ? 'Stake DBC amount'
                    : 'Unstake DBC amount'
                  }
                />
              </div>

              <div className={styles.section}>
                <SliderField
                  onChange={handleChangeStakePercent}
                  defaultValue={stakePercent}
                  min={sliderProps.min}
                  max={sliderProps.max}
                  value={stakePercent}
                  step={sliderProps.step}
                  tooltipVisible
                  size={matches.xs ? 'sm' : 'md'}
                  theme='gray-light'
                />
              </div>

              <div className={styles.section}>
                <div className={styles.actions}> 
                  <Button
                    type='button'
                    className={styles.actions_action}
                    theme='gray-light'
                    text={mode === 'deposit' ? 'Deposit' : 'Withdraw'}
                    size={matches.xs ? 'sm' : 'md'}
                    isDisabled={isStakeActionBtnDisabled}
                    onClick={handleConfirm}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Media>
    </Modal>
  )
}

export default StakeModal

export {
  Mode
}
