import React, { useMemo, useCallback, useState, useEffect }  from 'react'
import { useWeb3React } from '@web3-react/core'
import cn from 'classnames'
import BigNumber from 'bignumber.js'
import * as ethers from 'ethers'
import Media from 'react-media'
import { Redirect } from 'react-router'

import { TokenAmount } from '@decibel-coin/decibel-contracts/lib'
import { clubMembershipPrice, ethersToBigNumber } from '@decibel-coin/decibel-contracts/lib/contract-utils'
import { ClubMemberRole, IClub, IClubMember } from '@decibel-coin/decibel-sdk/lib/types/entities'

import useToast from 'hooks/useToast'
import useErrorHandler from 'hooks/useErrorHandler'
import useAuth from 'hooks/useAuth'
import { useAccount } from 'state/account/hooks'
import { useMasterContract }  from 'hooks/useContract'
import { FetchStatus, useGetDBCBalance } from 'hooks/useTokenBalance'
import { ApprovalState, useApproveCallbackV2 } from 'hooks/useApproveCallback'
import useRefresh from 'hooks/useRefresh'

import { Button, ConnectMetamaskBtn } from 'components'
import { ToastDescriptionWithTx2 } from 'components/Toast'
import StakeModal, { Mode } from 'components/modals/StakeModal'
import GetClubInviteModal from 'components/modals/GetClubInviteModal'

import { formatDBCToken, formatPercent, breakpoints } from 'utils'
import tokens from 'config/constants/tokens'
import { ApiService } from 'services'

import styles from './styles.module.scss'

interface IProps {
  club: IClub
  clubMember?: IClubMember | undefined
  loadClubMember: () => Promise<void>
}

const dbcMinApproveAmount = new TokenAmount(tokens.dbc, clubMembershipPrice)
const dbcApproveAmount = new TokenAmount(tokens.dbc, ethersToBigNumber(ethers.constants.MaxUint256).toString())

const ClubMemberDetails: React.FC<IProps> = ({
  club,
  clubMember,
  loadClubMember
} ) => {
  const { handleError } = useErrorHandler()
  const { account: address } = useWeb3React()
  const masterContract = useMasterContract()
  const { isAuthorized, isSignInPending, account, signInLocal, signOut } = useAccount()
  const { fastRefresh } = useRefresh()
  const { toastSuccess } = useToast()
  
  const { balance: dbcBalance, fetchStatus: dbcFetchStatus } = useGetDBCBalance()
  const [dbcApprovalState, approveDbcCallback] = useApproveCallbackV2(dbcApproveAmount, masterContract.address, dbcMinApproveAmount)

  const [clubStakingAmount, setClubStakingAmount] = useState<BigNumber | undefined>(undefined)
  const [isStartClubMembershipActionPending, setIsStartMembershipActionPending] = useState(false)
  const [isStopClubMembershipActionPending, setIsStopMembershipActionPending] = useState(false)

  const [stakingModalState, setStakingModalState] = useState<{
    mode: Mode
    isOpened: boolean
  }>({ isOpened: false, mode: 'deposit' })

  const [clubInviteModalState, setClubInviteModalState] = useState<{
    isOpened: boolean
  }>({ isOpened: false })

  const loadStaking = useCallback(async () => {
    try {
      const clubStakingPool = await masterContract.getClubStakingPool(club.chClubId)

      setClubStakingAmount(ethersToBigNumber(clubStakingPool.totalStakeAmount))
    } catch (error) {
      setClubStakingAmount(new BigNumber(0))
    }
  }, [club, masterContract])

  const handleApproveAllowance = async () => {
    if (
      club &&
      club.chClub &&
      address
    ) {
      try {
        await approveDbcCallback()
      } catch (error) {
        handleError(error)
        console.error(error)
      }
    }
  }

  const checkMembership = async () => {
    await ApiService.becomeClubMember({
      clubId: club.id
    })

    await ApiService.verifyClubMemberMembership({
      clubId: club.id
    })

    return loadClubMember()
  }

  const handleStartClubMembership = async () => {
    if (!club || !address) return 

    try {
      setIsStartMembershipActionPending(true)

      await checkMembership()

      const { signature, nonce } = await ApiService.getSubscribeOnClubMessageSignature({
        clubId: club.chClubId,
        role: ClubMemberRole.member
      })

      const startClubMembershipCall = await masterContract.startClubMembership(
        nonce,
        signature,
        club.chClubId,
        1,
        { from: address },
      )

      await startClubMembershipCall.wait()

      await ApiService.verifyClubMemberMembership({
        clubId: club.id
      })

      await loadClubMember()

      toastSuccess(
        'Membership started!',
        <ToastDescriptionWithTx2 txHash={startClubMembershipCall.hash}>
          Successfully started membership and staked minimal amount
        </ToastDescriptionWithTx2>,
      )

      setIsStartMembershipActionPending(false)
    } catch (error) {
      handleError(error)

      setIsStartMembershipActionPending(false)

      console.error(error)
    }
  }

  const handleUnsubscribeOnClub = async () => {
    if (!club || !address) return 

    try {
      setIsStopMembershipActionPending(true)

      const stopClubMembershipCall = await masterContract.stopClubMembership(
        club.chClubId,
        { from: address },
      )

      await stopClubMembershipCall.wait()

      await ApiService.verifyClubMemberMembership({
        clubId: club.id
      })

      await loadClubMember()

      toastSuccess(
        'Membership stoped!',
        <ToastDescriptionWithTx2 txHash={stopClubMembershipCall.hash}>
          Successfully stopped membership
        </ToastDescriptionWithTx2>,
      )

      setIsStopMembershipActionPending(false)
    } catch (error) {
      handleError(error)

      setIsStopMembershipActionPending(false)

      console.error(error)
    }
  }

  const handleGetClubInvite = () => {
    setClubInviteModalState({
      isOpened: true,
    })

    loadClubMember()
  }

  const handleDeposit = () => {
    setStakingModalState({
      isOpened: true,
      mode: 'deposit'
    })
  }

  const handleWithdraw = () => {
    setStakingModalState({
      isOpened: true,
      mode: 'withdraw'
    })
  }

  const handleCloseStakingModal = () => {
    setStakingModalState({
      isOpened: false,
      mode: 'deposit'
    })

    loadClubMember()
  }

  const handleCloseGetClubInviteModal = () => {
    setClubInviteModalState({
      isOpened: false,
    })

    loadClubMember()
  }

  const avRewardPerRoom = useMemo(() => {
    return (clubMember && clubMember.roomsCount > 0)
      ? new BigNumber(clubMember.totalReward).div(clubMember.roomsCount)
      : new  BigNumber(0)
  }, [clubMember])

  const isNotEnoughBalance = useMemo(() => {
    return (
      dbcBalance.isLessThan(clubMembershipPrice) || 
      dbcFetchStatus === FetchStatus.FAILED
    )
  }, [dbcBalance, dbcFetchStatus])

  const isEnableStakingPoolBtnShown = useMemo(() => {
    return (
      address &&
      dbcApprovalState !== ApprovalState.APPROVED
    )
  }, [dbcApprovalState, address])

  const isEnableStakingPoolBtnDisabled = useMemo(() => {
    return (
      !address ||
      dbcApprovalState === ApprovalState.PENDING
    )
      
  }, [dbcApprovalState, address])

  const isStartClubMembershipBtnShown = useMemo(() => {
    return (
      address &&
      (!clubMember || (clubMember && !clubMember?.hasMembership)) &&
      dbcApprovalState === ApprovalState.APPROVED
    )
  }, [address, clubMember, dbcApprovalState])

  const isStartClubMembershipBtnDisabled = useMemo(() => {
    return (
      isNotEnoughBalance ||
      !address
    )
  }, [address, isNotEnoughBalance])

  const isClubConnected = useMemo(() => {
    return (club && club.isConnectedToContract && (club.stakingPoolId !== undefined))
  }, [club])

  useEffect(() => {
    if (club) {
      loadStaking()
    }
  }, [club, fastRefresh, loadStaking])

  if (account && address && account.blockchainAddress && account.blockchainAddress !== address) {
    return <Redirect exact to="/connect-blockchain-account" />
  }

  return (
    <>
      <Media queries={{ xs: { maxWidth: breakpoints.xs } }}>
        {matches => (
          <div className={cn(styles.root)}>
            <div className={styles.content}>
              <div className={cn(styles.section, styles.section__reward, styles.reward)}>
                <div className={styles.section_title}>
                  Reward data
                </div>

                <div className={styles.section_content}>
                  <div className={styles.details}>
                    {/* <div className={styles.detail}>
                      <div className={styles.detail_name}>
                        Pending reward
                      </div>

                      <div className={styles.detail_value}>
                        {formatDBCToken(new BigNumber(0))}
                      </div>
                    </div> */}

                    <div className={styles.detail}>
                      <div className={styles.detail_name}>
                        Claimed reward
                      </div>

                      <div className={cn(styles.detail_value, styles.detail_value__highlighted)}>
                        {formatDBCToken(new BigNumber(clubMember?.totalReward || 0))}
                      </div>
                    </div>


                    <div className={styles.detail}>
                      <div className={styles.detail_name}>
                        Farmed rooms count
                      </div>

                      <div className={styles.detail_value}>
                        {clubMember?.roomsCount || 0}
                      </div>
                    </div>

                    <div className={styles.detail}>
                      <div className={styles.detail_name}>
                        Average reward per room
                      </div>

                      <div className={styles.detail_value}>
                        {formatDBCToken(avRewardPerRoom)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={cn(styles.section, styles.section__reward, styles.staking)}>
                <div className={styles.section_title}>
                  Staking data
                </div>

                <div className={styles.section_content}>
                  <div className={styles.details}>
                    <div className={styles.detail}>
                      <div className={styles.detail_name}>
                        Staking amount
                      </div>

                      <div className={cn(styles.detail_value, styles.detail_value__highlighted)}>
                        {formatDBCToken(new BigNumber(clubMember?.staking || 0))}
                      </div>
                    </div>

                    <div className={styles.detail}>
                      <div className={styles.detail_name}>
                        Staking share
                      </div>

                      <div className={styles.detail_value}>
                        {formatPercent(clubMember?.staking || 0, club.metrics.totalStaking)}
                      </div>
                    </div>
                  </div>
                </div>

                {(account && account.blockchainAddress && address && account.blockchainAddress === address && clubMember?.hasMembership) && (
                  <div className={cn(styles.section_footer, styles.reward_actions)}>
                    <div className={styles.reward_action}>
                      <Button
                        type='button'
                        theme='gray-light'
                        text='+ Enter staking'
                        size={matches ? 'xs' : 'sm'}
                        onClick={handleDeposit}
                      />
                    </div>

                    <div className={styles.reward_action}>
                      <Button
                        type='button'
                        theme='gray-light'
                        text='- Leave staking'
                        size={matches ? 'xs' : 'sm'}
                        onClick={handleWithdraw}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className={cn(styles.section, styles.section__reward, styles.membership)}>
                <div className={styles.section_title}>
                  Club membership subscription
                </div>

                <div className={styles.section_content}>
                  <div className={styles.details}>
                    <div className={styles.detail}>
                      <div className={styles.detail_name}>
                        Status
                      </div>

                      <div className={styles.detail_value}>
                        {clubMember?.hasMembership ? 'Active' : 'Not active'} 
                      </div>
                    </div>

                    <div className={styles.detail}>
                      <div className={styles.detail_name}>
                        Min stake amount
                      </div>

                      <div className={styles.detail_value}>
                        {formatDBCToken(new BigNumber(clubMembershipPrice))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={cn(styles.section_footer, styles.membership_actions)}>
                  {(account && account.blockchainAddress && address && account.blockchainAddress === address) && (
                      <>
                        {isEnableStakingPoolBtnShown && (
                          <Button
                            type='button'
                            className={styles.membership_action}
                            onClick={handleApproveAllowance}
                            isDisabled={isEnableStakingPoolBtnDisabled}
                            size={matches ? 'xs' : 'sm'}
                            text={
                              dbcApprovalState === ApprovalState.PENDING
                                ? ('Enabling ...')
                                : ("Enable")
                            }
                          />
                        )}

                        {(isStartClubMembershipBtnShown) && (
                          <Button
                            type='button'
                            className={styles.membership_action}
                            theme='gray-light'
                            onClick={handleStartClubMembership}
                            isDisabled={isStartClubMembershipBtnDisabled}
                            size={matches ? 'xs' : 'sm'}
                            text='Start membership'
                          /> 
                        )}

                        {(clubMember?.hasMembership) && (
                          <>
                            <Button
                              type='button'
                              className={styles.membership_action}
                              theme='green'
                              text='Get invite'
                              size={matches ? 'xs' : 'sm'}
                              onClick={handleGetClubInvite}
                            />

                            <Button
                              type='button'
                              className={styles.membership_action}
                              theme='red'
                              text='Unsubscribe'
                              size={matches ? 'xs' : 'sm'}
                              onClick={handleUnsubscribeOnClub}
                            />
                          </>
                        )}
                      </>
                    )}

                    {(!account || !address || (!account.blockchainAddress)) && (
                      <div className={styles.reward_action}>
                        <ConnectMetamaskBtn/>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Media>

      {(club && clubMember && stakingModalState.isOpened) && (
        <StakeModal
          isOpened={stakingModalState.isOpened}
          mode={stakingModalState.mode}
          club={club}
          clubMember={clubMember}
          onLoadData={() => loadClubMember()}
          onClose={handleCloseStakingModal}
        />
      )}

      {(club && clubMember && clubInviteModalState.isOpened) && (
        <GetClubInviteModal
          isOpened={clubInviteModalState.isOpened}
          club={club}
          onClose={handleCloseGetClubInviteModal}
        />
      )}
    </>
  )
}

export default ClubMemberDetails
