import { ethers } from 'ethers'
import { DecibelCoin, Bep20, Master } from '@decibel-coin/decibel-contracts/lib/contracts/types'

import { simpleRpcProvider } from 'utils/providers'

import {
  getDecibelCoinAddress,
  getMasterAddress,
  getMulticallAddress,
} from 'utils/addressHelpers'

import decibelCoinCompiled from '@decibel-coin/decibel-contracts/lib/contracts/compiled/DecibelCoin.json'
import masterCompiled from '@decibel-coin/decibel-contracts/lib/contracts/compiled/Master.json'
import bep20Compiled from '@decibel-coin/decibel-contracts/lib/contracts/compiled/Bep20.json'
import bep20Abi from 'config/abi/erc20.json'

import MultiCallAbi from 'config/abi/Multicall.json'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getDecibelCoinContract = (signer?: ethers.Signer | ethers.providers.Provider): DecibelCoin => {
  return getContract(decibelCoinCompiled.abi, getDecibelCoinAddress(), signer) as any as DecibelCoin
}

export const getMasterContract = (signer?: ethers.Signer | ethers.providers.Provider): Master => {
  return getContract(masterCompiled.abi, getMasterAddress(), signer) as any as Master
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  // return getContract(bep20Abi, address, signer) as any as Bep20
  return getContract(bep20Abi, address, signer)
}

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}