import React, { useState }  from 'react'
import cn from 'classnames'

import { TRoomPopulated, TRoomMemberPopulated } from '@decibel-coin/decibel-sdk/lib/types/entities'

import { Box, RoomDetails, RoomMemberDetails, Toggler } from 'components'

import styles from './styles.module.scss'

interface IProps {
  className?: string
  room: TRoomPopulated
  roomMember: TRoomMemberPopulated | undefined
}

const RoomPreview: React.FC<IProps> = ({
  className,
  room,
  roomMember
} ) => {
  const [isToggled, setIsToggled] = useState(false)

  const handleToggle = () => {
    setIsToggled(!isToggled)
  }

  const getToggler = () => (
    <Toggler
      text='Account details'
      isToggled={isToggled}
      onToggle={handleToggle}
    />
  )

  return (
    <Box className={cn(styles.root, className)}>
      <RoomDetails
        room={room}
        actions={roomMember ? getToggler() : undefined}
      />

      {(isToggled && roomMember) && (
        <>
          <div className={styles.root_separator}/>

          <RoomMemberDetails
            room={room}
            roomMember={roomMember}
          />
        </>
      )}
    </Box>
  )
}

export default RoomPreview
