import React, { FC, ChangeEvent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import cn from 'classnames'

import styles from './styles.module.scss'

interface IProps {
  className?: string
  text?: string
  to: string
  hasIcon?: boolean
  target?: '_blank'
}

const Link: FC<IProps> = ({
  className,
  text,
  to,
  hasIcon = true,
  target = '_self'
}) => (
  <RouterLink
    target={target}
    to={to}
    className={cn(styles.root, className)}
  >
    {text && (
      <div className={styles.root_text}>
        {text}
      </div>
    )}

    {hasIcon && (
      <div className={styles.root_icon}/>
    )}

  </RouterLink>
)
  
export default Link