import { ChainId, Token, TokenTicker } from '@decibel-coin/decibel-contracts'
import { getEnvVar } from '@decibel-coin/decibel-sdk/lib/utils'

import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const DBC_TOKEN_CONTRACT_ADDR = getEnvVar('APP_DBC_TOKEN_CONTRACT_ADDR')
const BNB_TOKEN_CONTRACT_ADDR = getEnvVar('APP_BNB_TOKEN_CONTRACT_ADDR')
const APP_BLOCKCHAIN_NETWORK_CHAIN_ID = getEnvVar('APP_BLOCKCHAIN_NETWORK_CHAIN_ID')

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  dbc: new Token(
    TESTNET,
    DBC_TOKEN_CONTRACT_ADDR,
    18,
    'DBC',
    'DecibelCoin token',
    'https://www.decibelcoin.io/',
  ),
  wbnb: new Token(
    MAINNET,
    BNB_TOKEN_CONTRACT_ADDR,
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(
    MAINNET,
    BNB_TOKEN_CONTRACT_ADDR,
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/'
  ),
  busd: new Token(
    MAINNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
}

export const testnetTokens = {
  dbc: new Token(
    TESTNET,
    DBC_TOKEN_CONTRACT_ADDR,
    18,
    'DBC',
    'DecibelCoin token',
    'https://www.decibelcoin.io/',
  ),
  wbnb: new Token(
    TESTNET,
    BNB_TOKEN_CONTRACT_ADDR,
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  bnb: new Token(
    TESTNET,
    BNB_TOKEN_CONTRACT_ADDR,
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  )
}

export const devnetTokens = {
  dbc: new Token(
    TESTNET,
    DBC_TOKEN_CONTRACT_ADDR,
    18,
    'DBC',
    'DecibelCoin token',
    'https://www.decibelcoin.io/',
  ),
  wbnb: new Token(
    TESTNET,
    BNB_TOKEN_CONTRACT_ADDR,
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  bnb: new Token(
    TESTNET,
    BNB_TOKEN_CONTRACT_ADDR,
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  )
}

const tokens = (): TokenList => {
  const chainId = APP_BLOCKCHAIN_NETWORK_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  if (parseInt(chainId, 10) === ChainId.DEVNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: devnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const getToken = (token: TokenTicker): Token | undefined => {
  if (Number(APP_BLOCKCHAIN_NETWORK_CHAIN_ID) === ChainId.DEVNET) {
    return devnetTokens[token]
  }

  if (Number(APP_BLOCKCHAIN_NETWORK_CHAIN_ID) === ChainId.TESTNET) {
    return testnetTokens[token]
  }

  if (Number(APP_BLOCKCHAIN_NETWORK_CHAIN_ID) === ChainId.MAINNET) {
    return mainnetTokens[token]
  }

  return undefined
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
